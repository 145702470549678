.dasboard_main {
  width: 85%;
  height: 100%;
  margin-left: 15%;
  color: #ffffff;
  #inst_img {
    max-width: 100%;
    max-height: 100%;
  }
  .box {
    width: 100%;
    background: rgba(57, 60, 82, 0.11);
    border-radius: 16px;
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
  }
  .top_box {
    height: 25vh;
    position: relative;
    .data {
      position: absolute;
      right: 2%;
    }
  }
  .box_bottom {
    height: 35vh;
    .box_tile {
      img {
        max-height: 4vh;
      }
      h5 {
        margin-bottom: 0;
      }
    }
    .total {
      h5 {
        margin-bottom: 0;
      }
      .complimentary_color {
        color: #009b3a;
      }
    }
    .data_table {
      width: 100%;
      .row {
        border-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 10%,
            rgba(255, 255, 255, 0.1) 15%,
            rgba(255, 255, 255, 0) 90%
          )
          30;
        border-width: 1px;
        border-style: solid;
        border-top: none;
      }
      .table_heading {
        color: rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 0;
      }
      .table_data {
        color: #ffffff;
        margin: 0;
        padding: 0;
      }
      .glowing {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(0, 155, 58, 1);
        box-shadow: 0 0 10px 5px rgba(0, 155, 57, 0.479);
      }
    }
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      height: 30vh;
    }
  }
  .side_box {
    width: 85%;
    height: 32%;
    background: rgba(57, 60, 82, 0.11);
    border-radius: 16px;
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
    .title_side_box {
      width: 100%;
      p {
        margin: 0;
        padding: 0;
        color: rgba(255, 255, 255, 0.5);
      }
      img {
        height: 60%;
      }
    }
    .total_number {
      color: #ffffff;
      font-size: 5.5rem;
      font-weight: 500;
      @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
        margin-top: 5%;
      }
    }
  }

  .image_box {
    height: 100%;
    aspect-ratio: 1;
    background: rgba(57, 60, 82, 0.11);
    border-radius: 16px;
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
    color: rgba(255, 255, 255, 0.5);
  }
  .institute_info {
    height: 100%;
    .complimentary_color {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
