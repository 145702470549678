.messaging_cont {
  width: 100%;
  height: 90%;
  // min-height: 90vh;
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
  //   color: #ffffff;

  .converstaion_cont {
    width: 30%;
    height: 100%;
    background-color: rgba(57, 60, 82, 0.2);
    border-radius: 16px;
    position: relative;
    .chat_icon {
      position: absolute;
      right: 5%;
    }

    .chatloader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .rce-citem {
      background-color: rgba(57, 60, 82, 0.2);
      border-radius: 16px;
      color: white;
      margin-bottom: 2%;
      .rce-citem-body--top-time {
        color: #ffffff !important;
      }
      .rce-citem-body--bottom-title {
        color: #ffffff !important;
      }
    }
  }
  .messaging {
    width: 68%;
    margin-left: 2%;
    height: 100%;
    background-color: rgba(57, 60, 82, 0.2);
    border-radius: 16px;
    padding: 2%;

    .messages {
      width: 100%;
      height: 90%;
      overflow-y: scroll;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */

      .messageloader {
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%);
      }

      &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari and Opera */
      }
    }
    .input_cont {
      width: 100%;
      //   height: 80px;
      //   position: absolute;
      //   bottom: 2%;
      //   left: auto;
      //   right: auto;
      .colored_send {
        color: rgba(0, 172, 64, 1);
      }
      .colored_send:hover {
        color: rgba(0, 172, 64, 0.8);
      }
    }
  }
  .rce-mbox {
    background-color: rgba(57, 60, 82, 0.2);
    color: #ffffff;
  }
  .rce-mbox-right {
    background-color: rgba(102, 105, 135, 0.2);
  }
  .rce-mbox-left-notch {
    fill: transparent !important;
  }
  .rce-mbox-right-notch {
    fill: transparent !important;
  }
  .rce-mbox-time {
    color: #ffffff !important;
  }
}
.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // background-color: green;
  border: 2px solid green;
  .plus_icon {
    color: green;
  }
}
.circle:hover {
  background-color: green;
  .plus_icon {
    color: white;
  }
}
.player_message {
  height: 10vh;
  width: 12vw;

  background-color: rgba(57, 60, 82, 0.2) !important;

  border-radius: 16px;
  .player_message_image {
    height: 90%;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: rgba(2, 6, 34, 0.2);
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.player_message:hover {
  border: 1px solid #028a37;
}
.message_title {
  color: #ababab;
}
.message_top_box {
  // border-bottom: 1px solid #ababab;
  // color: #40424f33;
  // color: #5b5b5f33;
}
.user_type_cont {
  p {
    color: #ababab;
  }

  .user_message {
    width: 12vw;
    height: 24vh;
    z-index: 3;
    /* From https://css.glass */
    /* From https://css.glass */
    background: rgba(57, 60, 82, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(13.8px);
    -webkit-backdrop-filter: blur(13.8px);
    transition: all 0.1s ease-in-out;
    img {
      max-height: 55%;
    }
  }
  .user_message:hover {
    transform: scale(1.05);
    border: 1px solid #009b3a;
  }
  .Active {
    border: 1px solid #009b3a;
    img {
      filter: brightness(0) saturate(100%) hue-rotate(90deg);
    }
  }
}
#initiate_convo_modal-body {
  height: 60vh !important;
  border: #009b3a;
}
.mantine-Modal-content {
  border: 1px solid #009b3a !important;
}
#search_player {
  width: 100%;
}

.contactSearchingLoader {
  margin-top: 70px;
}
