.hero-section {
  background: none;
}

@media screen and (min-width: 768px) {
  .hero-section {
    background-image: url("../../public/images/home-new1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
}
