.search_result {
  background-color: rgba(57, 60, 82, 0.2);
  width: 100%;
  height: 40vh;
  border-radius: 16px;
  overflow: hidden;
  .team {
    p {
      padding: 0;
      margin: 0;
      color: #c8c8c8;
    }
  }
  #player_image {
    max-height: 60%;
    // max-width: 100%;
  }
  .player_info {
    h3 {
      color: #ffffff;
    }
    .player_data {
      width: 100% !important;
      margin-bottom: 3%;
      p {
        margin: 0;
        padding: 0;
        font-size: 0.9rem;
      }
      .title {
        color: #ababab;
        width: 36%;
      }
      .stat {
        color: #ffffff;
      }
    }
    .button_cont {
      width: 100% !important;
      .mantine-Button-root {
        width: 75%;
      }
    }
  }

  .ring_stat {
    p {
      color: #ffffff;
      font-size: 0.8rem;
      margin: 0;
    }
  }
}
