.createAcc_confirmation_cont {
  width: 100vw;
  height: 100vh;
  background-color: #101222;
  position: relative;
}

h1 {
  color: #ffffff;
}
h4 {
  text-align: center;
  color: #ababab;
  font-size: 1.5rem;
}
.CreateAcc_gray_scale {
  height: 40%;
  margin-top: 5%;
  filter: grayscale(1);
}
#logo {
  position: absolute;
  top: 4%;
}
