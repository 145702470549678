.phone_error_cont {
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: rgba(16, 18, 34, 1);
  position: relative;
  color: rgba(255, 255, 255, 0.5);

  .phone_error_logo {
    position: absolute;
    top: 5%;
  }
  .phone_error_img {
    width: 90%;
  }
}
