.Training_cont {
  width: 100%;
  height: 90%;
  color: #ffffff;
  .training_top {
    width: 100%;
  }
  .training_main {
    width: 100%;
    .date_cont {
      width: 90%;
      .date {
        background-color: rgba(57, 60, 82, 0.2);
        border-radius: 20px;
        p {
          margin: 0;
          padding: 0;
        }
      }
      .date_box {
        width: 20%;
        height: 25vh;
        border-radius: 16px;
        border: 1px solid #ffffff;
        transition: all ease-in-out 0.1s;
        cursor: pointer;
        .design_box {
          width: 100%;
          height: 10%;
          transition: all ease-in-out 0.1s;
          background: linear-gradient(
            90deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 108.03%
          );
          opacity: 0.1;
        }
        .date_inner {
          width: 100%;
          height: 60%;
          position: relative;
          p {
            margin: 0;
            padding: 0;
            font-size: 1rem;
          }
          h1 {
            padding: 0;
            margin: 0;
            font-size: 2.5rem;
          }
          .card {
            width: 30%;
            aspect-ratio: 1;
            background-color: rgba(37, 39, 59, 1);
            position: absolute;
            right: 5%;
            bottom: 5%;
            color: #ffffff;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }
          .card:nth-child(1) {
            bottom: 35%;
          }
          .card:nth-child(2) {
            bottom: 25%;
          }
          .card:nth-child(3) {
            bottom: 15%;
          }
        }
        @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
          (min-resolution: 192dpi) {
          height: 20vh;
        }
      }
      .date_box:hover {
        border: 1px solid #028a37;
        .design_box {
          background: linear-gradient(
            90deg,
            #009b3a 0%,
            rgba(0, 155, 58, 0) 108.03%
          );
          opacity: 1;
        }
      }
    }
    .delete_box {
      width: 20%;
      height: 25vh;
      border-radius: 16px;
      border: 1px solid #ffffff;
      transition: all ease-in-out 0.1s;
      cursor: pointer;
      .design_box {
        width: 100%;
        height: 10%;
        transition: all ease-in-out 0.1s;
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 108.03%
        );
        opacity: 0.1;
      }
      .date_inner {
        width: 100%;
        height: 60%;
        position: relative;
        p {
          margin: 0;
          padding: 0;
          font-size: 1rem;
        }
        h1 {
          padding: 0;
          margin: 0;
          font-size: 2.5rem;
        }
        .card {
          width: 30%;
          aspect-ratio: 1;
          background-color: rgba(37, 39, 59, 1);
          position: absolute;
          right: 5%;
          bottom: 5%;
          color: #ffffff;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .card:nth-child(1) {
          bottom: 35%;
        }
        .card:nth-child(2) {
          bottom: 25%;
        }
        .card:nth-child(3) {
          bottom: 15%;
        }
      }
      @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
        height: 20vh;
      }
    }
    .delete_box:hover {
      border: 1px solid #9b0000;
      .design_box {
        background: linear-gradient(
          90deg,
          #9b0000 0%,
          rgba(0, 155, 58, 0) 108.03%
        );
        opacity: 1;
      }
    }
    .user_training {
      width: 100%;
      height: 25vh;
      background-color: rgba(57, 60, 82, 0.2);
      border-radius: 16px;
      .player_image {
        height: 100%;
        width: 20%;
        img {
          max-height: 80%;
        }
        p {
          margin: 0;
          padding: 0;
        }
      }
      .attendance {
        width: 20%;
        height: 80%;
        border-right: 3px solid #393c52;
        p {
          margin: 0;
          padding: 0;
        }
        .top_attendance {
          border-bottom: 3px solid #393c52;
        }
        .complimnetary {
          color: #98989a;
          font-size: 0.8rem;
        }
      }
      #last_metrics {
        border-right: none;
      }
      .metrics {
        width: 20%;
        height: 80%;
        border-right: 3px solid #393c52;
        .metric {
          width: 100%;
        }
        p {
          margin: 0;
          padding: 0;
        }
        .complimentary {
          color: #98989a;
          font-size: 0.8rem;
        }
        .highlight {
          color: #028a37;
        }
        .metric_value {
          width: 100%;
        }
      }
    }
  }
  .empty {
    width: 90%;
    height: 90%;
    text-align: center;
    h4 {
      color: rgba(255, 255, 255, 0.5);
    }
    .grayscale {
      height: 50%;
      filter: grayscale(1);
    }
  }
  .empty_2 {
    width: 90%;
    // height: 60%;
    img {
      max-height: 45vh;
    }
    text-align: center;
    h4 {
      color: rgba(255, 255, 255, 0.5);
    }
    .grayscale {
      height: 50%;
      filter: grayscale(1);
    }
  }
}
.modal_cont {
  width: 100%;
}
.dt_cont {
  width: 60%;
}
.player_values_cont {
  width: 95%;
}
.feedback_cont {
  width: 32%;
}
.inner_box {
  background-color: rgba(57, 60, 82, 0.2);
  border-radius: 10px;
}
// .mantine-Pill-root {
//   background-color: rgba(57, 60, 82, 0.2) !important;
//   color: #ffffff !important;
// }
.mantine-Modal-content::-webkit-scrollbar {
  display: none !important;
}
.player {
  height: 20vh;
  aspect-ratio: 1;
  background-color: rgba(57, 60, 82, 0.2) !important;

  border-radius: 6px;
  img {
    height: 50%;
    width: auto; // This will maintain the image's aspect ratio
    display: block;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }
}
.player:hover {
  border: 1px solid #028a37;
}
.player_card {
  height: 7vh;
  width: 10vw;
  background-color: rgba(57, 60, 82, 0.2) !important;

  border-radius: 6px;
  img {
    height: 90%;
    width: auto; // This will maintain the image's aspect ratio
    display: block;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
  }
}
.player_card:hover {
  border: 1px solid #028a37;
}
.activePlayer {
  border: 1px solid #028a37;
}
.active {
  border: 1px solid #028a37;
}

// .mantine-Pill-label {
//   cursor: pointer !important;
// }
.mantine-Textarea-input {
  background-color: rgba(57, 60, 82, 0.2) !important;
  color: #ffffff !important;
  border: none !important;
}
.mantine-Input-input {
  background-color: rgba(57, 60, 82, 0.2) !important;
  color: #ffffff !important;
  border: none !important;
}
// .MuiPickersLayout-root {
//   background-color: rgba(57, 60, 82, 0.2) !important;
//   color: #ffffff !important;
// }
// .MuiPickersToolbar-root {
//   color: #ffffff !important;
// }
// .MuiPickersLayout-toolbar {
//   color: #ffffff !important;
// }
.mantine-NumberInput-control {
  border: none !important;
}
.date_modal {
  .mantine-Modal-content {
    // height: 85vh !important;
    background-color: #101222 !important;
  }
}
.main_modal_cont {
  height: 100%;
}
#training_modal-body {
  height: 90% !important;
}
#date_delete_modal-body {
  .mantine-Modal-body {
    height: 90% !important;
  }
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.player_delete:hover {
  border: 1px solid #9b0000;
}
