@tailwind base;
@tailwind components;
@tailwind utilities;

.mantine-Progress-root {
  top: auto !important;
  bottom: 0 !important;
  background-color: #c7d3eb !important;
}
.mantine-Progress-section {
  border-radius: 0px 30px 30px 0px !important;
}
.mantine-Progress-section::before {
  display: none !important;
}
.toast_logo {
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}
.Toastify__toast-body {
  position: relative !important;
}
