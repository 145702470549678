.container {
  display: flex;
  width: 100%;
}

.left-column {
  flex: 1;
  padding: 20px;
}
.my-items {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  padding: 5px;
}
.video-row {
  height: 250px;
}
.right-column {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-div {
  margin-bottom: 20px;
}

.description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.description.show {
  max-height: 100px; /* Adjust based on your content */
}

/* video-slider-image {
  width: 100%;
  transition: transform 1.5s ease-in-out;
} */

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 100px;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  transition: opacity 0.5s ease; /* Smooth transition for the opacity */
}

.image-container .fading-in {
  opacity: 1;
  z-index: 2; /* Ensure it covers the fading-out image */
}

.image-container .fading-out {
  opacity: 0;
  z-index: 1;
}

.video-row {
  overflow: hidden;
  transition: all 0.3s ease;
  height: 95px; /* default height when text is not shown */
  box-shadow: none; /* No shadow by default */
  border-radius: 50px;
}

.video-row.expanded {
  height: 220px;
  box-shadow: inset 12px -12px 15px -10px #0f1430;
}

.content {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-row.expanded .content {
  opacity: 1;
}

.content {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: relative; /* Ensure text appears above the pseudo-element */
  z-index: 1;
}

.video-row.expanded .content {
  opacity: 1;
}

.video-slider-image {
  height: auto;
  transition: transform 0.3s ease;
}
