#Signup_cont {
  width: 100vw;
  height: 100vh;
  background-color: rgba(16, 18, 34, 1);
  .top_cont {
    font-style: italic;
    height: 10vh;
    width: 100%;
    color: #ffffff;
  }

  .form_cont {
    color: #ffffff;
    position: absolute;
    left: 60%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
  .signup_form {
    height: 65vh;
    width: 50vw;
    @media (min-width: 1600px) {
      width: 30vw;
      height: 50vh;
    }
    border-radius: 15px;
    background-color: rgba(57, 60, 82, 0.2);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    padding-bottom: 45%;
    select {
      background-color: #1e2132 !important;
      color: white !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    option {
      background-color: #1e2132 !important;
      color: white !important;
    }
    .mantine-InputWrapper-label {
      margin-bottom: 1%;
    }
    .mantine-Input-input {
      background-color: rgba(57, 60, 82, 0.2);
      color: #ffffff;
    }

    .button_cont {
      width: 100% !important;
    }
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      width: 55vw;
      height: 50vh;
    }
  }
  #year {
    option {
      background-color: rgba(57, 60, 82, 0.2) !important;
      color: #ffffff !important;
    }
  }
}
