.parents_main {
  color: #ffffff;
}
.player_sidebar {
  width: 85%;
  height: 20%;
  background-color: rgba(57, 60, 82, 0.2);
  img {
    max-height: 100%;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }
  border: 1px solid #009b3a;
  border-radius: 16px;
}
.user_training {
  width: 100%;
  height: 25vh;
  background-color: rgba(57, 60, 82, 0.2);
  border-radius: 16px;
  color: #ffffff;
  .player_image {
    height: 100%;
    width: 20%;
    img {
      max-height: 80%;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .attendance {
    width: 20%;
    height: 80%;
    border-right: 3px solid #393c52;
    p {
      margin: 0;
      padding: 0;
    }
    .top_attendance {
      border-bottom: 3px solid #393c52;
    }
    .complimnetary {
      color: #98989a;
      font-size: 0.8rem;
    }
  }
  #last_metrics {
    border-right: none;
  }
  .metrics {
    width: 20%;
    height: 80%;
    border-right: 3px solid #393c52;
    .metric {
      width: 100%;
    }
    p {
      margin: 0;
      padding: 0;
    }
    .complimentary {
      color: #98989a;
      font-size: 0.8rem;
    }
    .highlight {
      color: #028a37;
    }
    .metric_value {
      width: 100%;
    }
  }
}
.avatar_image_cont {
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
}
