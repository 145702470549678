.dashboard_content {
  width: 85%;
  height: 100%;

  margin-left: 15%;
  .main_top {
    width: 100%;
    height: 10%;
    color: rgba(255, 255, 255, 0.9);
    .mantine-Input-input {
      background-color: transparent;
      border-color: rgba(57, 60, 82, 0.6);
    }
    .input_cont {
      width: 35%;
    }
    .main_top_right {
      width: 7%;
      .notification_cont {
        color: #ffffff;
      }
    }
  }
  .main_content {
    width: 100%;
    height: 90%;
    .image_cont {
      height: 100%;
      position: relative;
      background-image: url("../../public/images/player_dash_bg.png");
      background-size: 100%;
      background-position: top;
      background-repeat: no-repeat;
      position: relative;

      #main_image {
        object-fit: contain;
        max-height: 47vh;
        position: absolute;
        top: 0;

        // filter: drop-shadow(10px 9px 49px #a4a4a43a);
        @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
          (min-resolution: 192dpi) {
          max-height: 85vh;
        }
      }
      .info_box {
        width: 100%;
        background: rgba(57, 60, 82, 0.11);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(9.4px);
        -webkit-backdrop-filter: blur(9.4px);
        color: #ffffff;
        .info_content {
          width: 100%;
          position: relative;
          .college {
            color: rgba(255, 255, 255, 0.7);
            font-weight: 500;
          }
          h4 {
            padding: 0;
            margin: 0;
          }
          h5 {
            padding: 0;
            margin: 0;
            color: rgba(255, 255, 255, 0.5);
          }
          p {
            padding: 0;
            margin: 0;
          }
          .player_name {
            color: rgba(255, 255, 255, 0.9);
          }
          .categry_cont {
            margin-right: 15%;
            .category {
              color: rgba(255, 255, 255, 0.5);
              margin-bottom: 10%;
            }
            font-size: 1.1rem;
            font-weight: lighter;
          }
          .design_3d {
            background-color: rgba(24, 26, 44, 1);
            width: 83%;
            height: 10vh;
            border-radius: 20px;
            z-index: 2;
            // span {
            //   color: rgba(0, 155, 58, 1);
            // }
            @media (min-width: 1024px) and (max-width: 1300px) {
              width: 90%;
            }
          }
          .compliement_box {
            width: 83%;
            z-index: 0;
            border-radius: 20px;
            position: absolute;
            overflow: hidden;
            @media (min-width: 1024px) and (max-width: 1300px) {
              width: 90%;
            }
            height: 10vh;
            bottom: -20%;
            .section {
              width: 25%;
              height: 100%;
            }
            #section-1 {
              background-color: #00722b;
            }
            #section-2 {
              background-color: #00a03c;
            }
            #section-3 {
              background-color: #00c74f;
            }
            #section-4 {
              background-color: #00e65f;
            }
          }
        }
      }
      // #main_image {
      //   z-index: 10;
      //   position: relative;
      //   bottom: 0;
      //   left: auto;
      //   right: auto;

      //   filter: drop-shadow(28px 9px 49px #055f4b);
      // }
      // #reflection {
      //   position: absolute;
      //   right: 5%;
      //   top: -15%;
      //   max-height: 80%;
      //   z-index: 1;
      // }
    }
    .info_top {
      padding-left: 5%;
      padding-right: 5%;
      .team_details {
        p {
          margin: 0;
          margin-left: 2%;
          padding: 0;
          color: #ffffff;
        }
      }
      h1 {
        color: #ffffff;
      }
      .info {
        color: #ffffff;
        background-color: rgba(57, 60, 82, 0.2);
        border-radius: 16px;
        width: 80%;
        p {
          margin: 0;
          padding: 0;
        }
      }
      .player_details {
        color: #ffffff;
        p {
          margin: 0;
          font-size: 1.3rem;
        }
        .categories {
          color: #ababab;
        }
      }
    }

    .technincal_metrics {
      color: rgba(255, 255, 255, 0.9);
      /* From https://css.glass */
      /* From https://css.glass */
      width: 100%;
      background: rgba(57, 60, 82, 0.11);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(9.4px);
      -webkit-backdrop-filter: blur(9.4px);
    }
    .general_metrics {
      /* From https://css.glass */
      width: 100%;
      background: rgba(57, 60, 82, 0.11);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(9.4px);
      -webkit-backdrop-filter: blur(9.4px);
      color: rgba(255, 255, 255, 0.9);
      .data_cont {
        .data {
          h6 {
            color: rgba(255, 255, 255, 0.5);
            padding: 0;
            margin: 0;
          }
          p {
            margin-bottom: 1%;
            padding: 0;
          }
          img {
            width: 20px;
            height: 20px;
          }
          .quant {
            color: rgba(142, 142, 142, 1);
          }
        }
      }
    }
    .top_metric_cont {
      width: 100%;
      background-color: rgba(57, 60, 82, 0.2);
      border-radius: 10px;
      p {
        margin: 0;
        padding: 0;
        color: #ffffff;
      }
      .data {
        color: #ababab;
      }
    }
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      .row {
        height: 100% !important;
      }
    }
  }
}

.mantine-Progress-root {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.mantine-Accordion-root {
  width: 100%;

  border-radius: 10px;
  border-color: none !important;
  p {
    margin-bottom: 2%;
  }
}
// .mantine-Menu-dropdown {

// }
.mantine-Accordion-item {
  width: 100%;
  background-color: rgba(57, 60, 82, 0.2) !important;
  &[data-active] {
    border-color: transparent;
  }
}

.mantine-Accordion-label {
  color: #ffffff !important;
}
.mantine-Accordion-chevron {
  color: #ffffff !important;
}
.mantine-Menu-dropdown {
  background: linear-gradient(
    87.76deg,
    #1b1e32 4.31%,
    #101222 108.9%
  ) !important;
  width: 25vw !important;
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: none !important;
  border-radius: 16px !important;
}
.mantine-Menu-dropdown::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mantine-Menu-dropdown {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-direction: column;
}
.mantine-Menu-item {
  width: 95% !important;
  color: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #009b3a !important;
  border-radius: 20px !important;
  align-self: center !important;
  position: relative !important;
  .notifications_design {
    position: absolute;
    width: 1%;
    height: 65%;
    border-radius: 0px 10px 10px 0px;
    background-color: rgba(0, 155, 58, 1);
    left: 0;
    top: 17.5%;
    // box-shadow: 0px 1px 2px rgba(0, 155, 58, 1), 0px 2px 4px rgba(0, 155, 58, 1),
    //   0px 4px 8px rgba(0, 155, 58, 1), 0px 8px 16px rgba(0, 155, 58, 1);
  }
  .button_cont {
    width: 100%;
    button {
      width: 8vw;
    }
  }
}
.mantine-Menu-item:hover {
  background-color: transparent !important;
}
.mantine-Menu-item {
  &[data-hovered] {
    background-color: transparent !important;
  }
}
.no_notifications_cont {
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
  .metrics_column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }
}
#player_update_modal-body {
  height: 50vh;
  .update_player_cont {
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    .player_update_header {
      font-weight: lighter;
    }
    .input_cont {
      width: 95%;
      color: rgba(255, 255, 255, 0.6);

      .input_label {
        font-weight: lighter;
        font-size: 1.1rem;
      }
    }
  }
}
