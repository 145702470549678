@import url("https://fonts.googleapis.com/css2?family=Anek+Gurmukhi:wght@200;300;500;600;700&display=swap");
$mobile: 480px;
body {
  margin: 0 !important;
  padding: 0 !important;

  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
}
.pricing_home_cont {
  width: 100vw;
  height: 350vh;
  @media (max-width: $mobile) {
    height: 780vh;
    overflow-x: hidden;
  }
  padding: 0;
  margin: 0;
  font-family: "Anek Gurmukhi", sans-serif;
  background-color: #0a0d1e;

  .pricing_landing {
    width: 100vw;
    height: 180vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-top: 2%;
    // padding-left: 5%;
    // padding-right: 5%;
    @media (max-width: $mobile) {
      height: 120vh;
    }
  }
  .pricing_landing:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    opacity: 0.1;
    background-image: url("../../public/images/home_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .landing_top {
    margin-left: 5%;
    margin-right: 5%;
    @media (max-width: $mobile) {
      margin-top: 10%;
    }

    .navbar {
      display: flex;
      justify-content: center;
    }
  }

  //.......................

  //.......................

  // .grid-item img {
  //   width: 650px;
  //   height: auto;
  // }
  #top_logo {
    @media (max-width: $mobile) {
      width: 15%;
    }
  }
  #dashboard_btn {
    @media (max-width: $mobile) {
      display: none;
      visibility: hidden;
    }
  }
  #elipse_1 {
    width: 100vw;
    height: 30vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(10vw);

    position: absolute;
    top: -10vw;
    border-radius: 50%;
  }
  .landing_main {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "Anek Gurmukhi", sans-serif;
    .spaced_text {
      color: rgba(255, 255, 255, 0.2);
      font-size: 1.1rem;
      letter-spacing: 10px;
      @media (max-width: $mobile) {
        font-size: 1rem;
        letter-spacing: 7px;
      }
    }

    .button_cont {
      width: 20vw;
      @media (max-width: $mobile) {
        width: 60vw;
      }
    }
  }
  .laptop_cont {
    opacity: 1;
    height: 90vh;
    position: relative;
    img {
      opacity: 1;
    }
    #laptop_image {
      position: absolute;
      z-index: 3;
      filter: drop-shadow(10px 9px 59px #a4a4a44d);
      @media (max-width: $mobile) {
        max-width: 90vw;
      }
    }
    @media (max-width: $mobile) {
      height: 40vh;
    }
  }
}

.parental_engagement {
  width: 100vw;
  height: 100vh;
  text-align: center;
  .button_cont {
    width: 20vw;
    @media (max-width: $mobile) {
      width: 70vw;
    }
  }
  @media (max-width: $mobile) {
    height: 45vh;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.cta_form_features {
  width: 100vw;
  height: 120vh;
  padding-top: 2%;
  text-align: center;
  position: relative;
  overflow: hidden;
  h1 {
    color: #ffffff;
  }
  .form_outter {
    width: 40vw;
    height: 80vh;
    @media (max-width: $mobile) {
      width: 90vw;
    }
    /* From https://css.glass */
    background: rgba(57, 60, 82, 0.11);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(57, 60, 82, 0.07);
    z-index: 2;
    .choose_recepient {
      width: 80%;
      color: rgba(255, 255, 255, 0.5);
      .selected {
        color: #ffffff;
        text-decoration: underline;
      }
      h4 {
        cursor: pointer;
      }
    }
    .input_cont {
      width: 90%;
    }
    .button_cont {
      width: 40%;
      @media (max-width: $mobile) {
        width: 80%;
      }
    }
    .text_area_cont {
      width: 90%;
    }
    @media (min-width: 1023px) and (max-width: 1300px) {
      height: 85vh;
    }
  }
  #elipse_5 {
    width: 100vw;
    height: 60vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(4vw);

    position: absolute;
    bottom: -55vh;
    border-radius: 50%;
  }
}
.footer_features {
  width: 100vw;
  height: 30vh;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 2%;
  color: rgba(255, 255, 255, 0.7);
  .tag_cont {
    width: 100%;
    margin-top: 2%;
  }
  .logo_cont {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: start;
    img {
      height: 5vh;
    }
  }
  .tag {
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
  }
  .sitemap {
    margin-left: 30%;
  }
  .copyright {
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
  }
}
.pricing_headline {
  color: #ffffff;
  // text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  span {
    color: transparent;
    background-image: linear-gradient(45deg, #009b3a 0%, #2fed76 100%);
    background-clip: text;
  }
  @media (max-width: $mobile) {
    font-size: 1.3rem;
  }
}
.main_text {
  color: rgba(255, 255, 255, 0.4);
  // text-align: center;
  font-weight: 300;
  font-size: 1.3rem;

  span {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
  }
  @media (max-width: $mobile) {
    font-size: 0.9rem;
  }
}

//pricing table----------------

.pricing-table-wrapper {
  max-width: 71rem;
  margin: 0 auto;
}

.pricing-table {
  --pricing-table-color: hsl(219, 83%, 57%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
  grid-gap: 2em;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    padding: 2em;
    border: 1px solid #5c5f71;
    border-radius: 3em;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.02, 0.01, 0.47, 1),
      transform 200ms cubic-bezier(0.02, 0.01, 0.47, 1);

    &:hover {
      box-shadow: 0 40px 40px rgba(0, 0, 0, 0.16);
      transform: translate(0, -20px);
    }

    &--popular {
      position: relative;

      &::before {
        position: absolute;
        content: attr(data-popular);
        top: 2em;
        right: 2em;
        font-size: 0.9em;
        padding: 0 1em;
        background-color: hsl(51, 100%, 50%);
        border-radius: 1em;
        color: hsl(60, 5%, 15%);
      }
    }
  }
  &__item:nth-child(2) {
    margin-bottom: 60px;
    margin-top: -60px; /* Offset the margin-bottom with a negative margin-top */
  }

  &__img {
    margin: 2.5em auto 4em;
    width: 100%;
    max-width: 9.3em;
  }

  &__title {
    margin: 0;
    font-size: 1.4em;
    text-transform: capitalize;
    color: white;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  &__tagline {
    margin: 0;
    font-size: 3em;
    color: white;
    text-align: center;
  }

  &__price {
    color: var(--pricing-table-color);
    font-size: 2.5em;
    margin: 1em auto 0;
    font-weight: bold;
  }

  &__label {
    margin: 0;
    color: hsl(216, 3%, 63%);
    font-size: 0.9em;
  }

  &__save {
    color: hsl(122, 39%, 49%);
    font-weight: bold;
  }

  &__products {
    margin: 1.87em 0;
    padding: 0;
    list-style-type: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__product {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    color: white;

    span {
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: inherit;
      transition: color 0.2s ease-in-out;
      margin: 8px;

      &:hover {
        color: var(--pricing-table-color);
      }
    }

    // &::before {
    //   content: " ";
    //   width: 0.5em;
    //   height: 0.5em;
    //   margin-inline-end: 1em;
    //   border-radius: 50%;
    //   background-color: var(--pricing-table-color);
    // }

    // &--excluded::before {
    //   content: " ";
    //   width: 0.5em;
    //   height: 0.5em;
    //   margin-inline-end: 1em;
    //   border-radius: 0;
    //   color: red;
    //   background: no-repeat
    //     url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 20' id='entypo-cross' width='32' height='32' fill='hsl(4, 90%, 58%)'%3E%3Cg%3E%3Cpath d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z'/%3E%3C/g%3E%3C/svg%3E")
    //     center/contain;
    //   transform: scale(2.5);
    // }
  }

  &__button-req-access {
    background: var(--pricing-table-color);
    color: black;
    font-weight: bold;
    font-size: 22px;
    margin-top: auto;
    padding: 8px 8px;
    text-decoration: none;
    width: 100%;
    border-radius: 1.87em;
    text-align: center;
    transition: background 0.2s ease-in-out;
    text-transform: capitalize;
    background-color: white;

    &:hover {
      background-color: hsl(0, 0%, 80%); /* Darker shade of white */
    }
  }
  &__button-req-access-2 {
    background: var(--pricing-table-color);
    color: black;
    font-weight: bold;
    font-size: 22px;
    margin-top: auto;
    padding: 8px 8px;
    text-decoration: none;
    width: 100%;
    border-radius: 1.87em;
    text-align: center;
    transition: background 0.2s ease-in-out;
    text-transform: capitalize;
    background-color: #858fd8;

    &:hover {
      background-color: hsl(236, 44%, 56%);
    }
  }
  &__button-req-access-3 {
    background: var(--pricing-table-color);
    color: black;
    font-weight: bold;
    font-size: 22px;
    margin-top: auto;
    padding: 8px 8px;
    text-decoration: none;
    width: 100%;
    border-radius: 1.87em;
    text-align: center;
    transition: background 0.2s ease-in-out;
    text-transform: capitalize;
    background-color: #13bd53;

    &:hover {
      background-color: #089940;
    }
  }
}
