.player_grade_search_cont {
  width: 100%;
  height: 90%;
  color: #ffffff;

  .classes {
    width: 100%;
    .class {
      width: 22%;
      height: 30vh;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      .eclipse {
        position: absolute;
        border-radius: 50%;
        background: rgba(0, 155, 58, 0.2);
        filter: blur(3vw);
        width: 100%;
        height: 30%;
        top: 0;
      }
      .grade {
        color: #009b3a;
        font-size: 3rem;
      }
    }
    .class:hover {
      cursor: pointer;
      outline: 1px solid #009b3a;
    }
  }
  .fullwidth {
    width: 100%;
  }
  .row {
    width: 100%;
  }
  .table_top {
    background: rgba(57, 60, 82, 0.2);
    border-radius: 16px;
    height: 6vh;
    p {
      margin: 0;
      padding: 0;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  .table_row {
    border-radius: 16px;
    height: 6vh;
    p {
      margin: 0;
      padding: 0;
    }
    .highlight_box {
      background-color: rgba(47, 237, 118, 0.08);
      border-radius: 10px;
      color: #009b3a;
    }
    #delete_icon {
      cursor: pointer;
    }
    #delete_icon:hover {
      color: red;
    }
    #promote_icon {
      cursor: pointer;
    }
    #promote_icon:hover {
      color: #009b3a;
    }
  }
  .table_row:nth-child(odd) {
    background: rgba(57, 60, 82, 0.1);
  }
  .table_row:nth-child(even) {
    background: rgba(57, 60, 82, 0.2);
  }
}
#Promote_modal-body {
  height: 60vh;
  .promotion_box {
    width: 20%;
    aspect-ratio: 1/1;
    background: linear-gradient(
      180deg,
      rgba(57, 60, 82, 0.2) 0%,
      rgba(57, 60, 82, 0.102) 100%
    );
    backdrop-filter: blur(10.4377px);
    border-radius: 16px;
    cursor: pointer;
    p {
      font-size: 1.5rem;
    }
  }
  .promotion_box:hover {
    outline: 1px solid #009b3a;
  }
}
