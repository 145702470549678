.Notifications_cont {
  width: 100%;
  height: 90%;
  color: #ffffff;
  .main_notifications {
    height: 80%;
    width: 100%;
    .title_cont {
      width: 100%;
    }
    .Description_cont {
      width: 100%;
      height: 70%;
      @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
        height: auto;
      }
    }
    .button_width {
      width: 15vw;
    }
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      .row {
        height: 100% !important;
      }
      height: 90%;
    }
  }
  .sent_notifications_cont {
    background-color: rgba(57, 60, 82, 0.2);
    width: 100%;
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    border-radius: 16px;
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
    .sent_notifications_top {
      h5 {
        margin: 0;
        padding: 0;
        color: rgba(255, 255, 255, 0.5);
      }
      border-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 10%,
          rgba(255, 255, 255, 0.1) 15%,
          rgba(255, 255, 255, 0) 90%
        )
        30;
      border-width: 2px;
      border-style: solid;
      border-top: none;
    }
    .sent_notification {
      .sent_notification_top {
        width: 100%;
      }
      p {
        margin: 0;
        padding: 0;
      }
      .sent_notification_title {
        font-weight: bold;
        color: rgba(255, 255, 255, 0.7);
      }
      .sent_notification_date {
        color: rgba(255, 255, 255, 0.5);
      }
      .sent_notification_message {
        color: rgba(255, 255, 255, 0.5);
      }
      border-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 10%,
          rgba(255, 255, 255, 0.1) 15%,
          rgba(255, 255, 255, 0) 90%
        )
        30;
      border-width: 2px;
      border-style: solid;
      border-top: none;
    }
  }
  .sent_notifications_cont::-webkit-scrollbar {
    /* Chrome, Safari and Opera */
    display: none;
  }
}
#Notifications_modal-body {
  height: 60vh;
  .user_choice {
    background-color: #101222;
    color: #ffffff;
    border-radius: 16px;
    aspect-ratio: 1;
    width: 12vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.01s ease-in-out;
    img {
      height: 60%;
    }
  }
  .design_box {
    width: 100%;
    height: 15%;
    transition: all 0.1s ease-in-out;
    background: linear-gradient(
      91.2deg,
      rgba(255, 255, 255, 0.1) 3.97%,
      rgba(255, 255, 255, 0.01) 99.29%
    );
    h5 {
      margin: 0;
      padding: 0;
    }
  }
  .user_choice:hover {
    border: 1px solid #009b3a;
    .design_box {
      background: linear-gradient(
        90deg,
        #009b3a 0%,
        rgba(0, 155, 58, 0) 108.03%
      );
      opacity: 1;
    }
  }
  .select_button_cont {
    width: 10vw;
  }
  .Active {
    border: 1px solid #009b3a;
    .design_box {
      background: linear-gradient(
        90deg,
        #009b3a 0%,
        rgba(0, 155, 58, 0) 108.03%
      );
      opacity: 1;
    }
  }
  .Player_search_cont {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .search_button_cont {
    width: 10vw;
  }
  .active_user {
    border: 1px solid #009b3a;
  }
}
.chosen_team_players_cont {
  width: 80%;
  height: 35vh;
  border-radius: 16px;
  background-color: #101222;
  overflow-y: scroll;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
  .choosen_team_player {
    width: 100%;
    height: 10%;
    .choosen_team_player_image {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #101222;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      img {
        height: 80%;
      }
    }
    border-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 10%,
        rgba(255, 255, 255, 0.1) 15%,
        rgba(255, 255, 255, 0) 90%
      )
      30;
    border-width: 2px;
    border-style: solid;
    border-top: none;
    p {
      margin: 0;
      padding: 0;
    }
  }
}
.choosen_team_players_buttons_cont {
  width: 100%;
  .button_cont {
    width: 30%;
  }
}
@media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
  .notification_columns {
    align-items: center;
    justify-content: end;
  }
}
