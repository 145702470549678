.search_history_card {
  background-color: rgba(57, 60, 82, 0.2);
  color: #ffffff;
  border-radius: 16px;

  .title {
    color: #ababab;
  }
  .button_cont {
    width: 100% !important;
    button {
      width: 90%;
    }
  }
}
