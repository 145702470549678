.no_sidebar {
  width: 100%;
  height: 100%;
}
.settings_cont {
  width: 100%;
  height: 90%;
  .season_cont {
    .mantine-TextInput-root {
      width: 60%;
    }
  }
  .payment_info_cont {
    width: 100%;
    border-radius: 16px;
    background-color: rgba(12, 14, 28, 0.55);
    .heading {
      color: rgba(255, 255, 255, 0.5);
    }
    .value {
      color: rgba(255, 255, 255, 1);
    }
  }
  .heading_main {
    color: #ffffff;
  }
  .transactions {
    img {
      height: 20px;
    }
    p {
      margin: 0;
      padding: 0;
      color: #ffffff;
    }
    .Transaction_header {
      background-color: rgba(57, 60, 82, 0.2);
      border-radius: 16px;
    }
    .trainsaction_row {
      border-radius: 16px;
      .Status {
        border-radius: 16px;
      }
      .success {
        p {
          color: #009b3a;
        }
        border: 1px solid #009b3a;
      }
      .Failed {
        p {
          color: #ff0000;
        }
        border: 1px solid #ff0000;
      }
    }
    .trainsaction_row:nth-child(odd) {
      background-color: rgba(57, 60, 82, 0.05);
    }
    .trainsaction_row:nth-child(even) {
      background-color: rgba(57, 60, 82, 0.1);
    }
  }
  .contrast_box {
    outline: 1px solid #009b3a;
  }
  .paymentbox {
    background-color: rgba(24, 26, 44, 1);
    aspect-ratio: 1;
    width: 26vw;
    height: 160vh;
    border-radius: 16px;
    color: #ffffff;
    img {
      height: 1.5vh;
    }
    span {
      font-weight: 800;
    }
    p {
      font-size: 1rem;
      width: 14vw;
    }
    #dull {
      color: rgb(255, 255, 255, 0.5);
    }
    .mantine-Button-label {
      color: rgba(16, 18, 34, 1);
      font-weight: 700;
    }
  }
  .Price {
    font-weight: bold;
    font-size: 3rem;
  }
  .contrast_heading {
    color: #009b3a;
  }
}
