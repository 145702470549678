#reset_cont {
  width: 100vw;
  height: 100vh;
  background-color: rgba(16, 18, 34, 1);
  .top_cont {
    font-style: italic;
    height: 10vh;
    width: 100%;
    color: #ffffff;
  }
  // Inside your SCSS file

  .form_cont {
    color: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .login_form {
    height: 55vh;
    width: 50vw;
    // @media (min-width: 1023px) {
    //   width: 30vw;
    //   height: 50vh;
    // }
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      width: 35vw;
      height: 40vh;
    }
    // @media (min-width: 1024px) and (max-width: 1300px) {
    //   width: 40vw;
    //   height: 50vh;
    // }

    border-radius: 15px;
    background-color: rgba(57, 60, 82, 0.2);
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 7%;
    padding-bottom: 7%;

    .mantine-InputWrapper-label {
      margin-bottom: 1%;
    }
    .mantine-Input-input {
      background-color: rgba(57, 60, 82, 0.2);
      color: #ffffff;
    }
    .mantine-PasswordInput-input {
      border: none;
    }

    .button_cont {
      width: 100% !important;
      margin-top: 3rem;
      button {
        width: 100%;
        color: black;
      }
    }
    .forgot_password {
      width: 100% !important;
      p {
        font-size: 0.8rem;
        text-decoration: underline;
        color: #cbd5e1;
      }
    }
    .terms_conditions {
      width: 100% !important;
    }
  }
}

.sendemail {
  display: flex;
  justify-content: center;
  color: #ffffff;
  align-items: center;
}
