@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.back_arrow:hover {
  cursor: pointer;
  color: #009b3a;
}
.practice_box {
  width: 20%;
  height: 25vh;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  img {
    width: 40%;
  }

  .design_box {
    width: 100%;
    height: 20%;

    background: linear-gradient(
      91.2deg,
      rgba(255, 255, 255, 0.21) 3.97%,
      rgba(255, 255, 255, 0) 99.29%
    );
  }
  background: linear-gradient(107.46deg, #101222 30.13%, #17192b 103.72%);
  background: linear-gradient(
    180deg,
    rgba(57, 60, 82, 0.2) 0%,
    rgba(57, 60, 82, 0.102) 100%
  );
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
}
.practice_box:hover {
  border: 1px solid #028a37;
  .design_box {
    background: linear-gradient(90deg, #009b3a 0%, rgba(0, 155, 58, 0) 108.03%);
    opacity: 1;
  }
}

.delete_practice_box {
  width: 20%;
  height: 25vh;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  img {
    width: 40%;
  }

  .design_box {
    width: 100%;
    height: 20%;

    background: linear-gradient(
      91.2deg,
      rgba(255, 255, 255, 0.21) 3.97%,
      rgba(255, 255, 255, 0) 99.29%
    );
  }
  background: linear-gradient(107.46deg, #101222 30.13%, #17192b 103.72%);
  background: linear-gradient(
    180deg,
    rgba(57, 60, 82, 0.2) 0%,
    rgba(57, 60, 82, 0.102) 100%
  );
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
}
.delete_practice_box:hover {
  border: 1px solid #9b0000;
  .design_box {
    background: linear-gradient(90deg, #9b0000 0%, rgba(0, 155, 58, 0) 108.03%);
    opacity: 1;
  }
}

.custom_table {
  .rdt_Table {
    background-color: transparent;
  }
  .rdt_TableHeadRow {
    background-color: #191b2b;
    color: #009b3a;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 1%;
  }
  .rdt_TableCol {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdt_TableRow {
    background-color: #191b2b;
    color: #ffffff;
    border-radius: 10px;
    margin-bottom: 1%;
  }
  .rdt_TableCell {
    border-right: 1px solid #ffffff25;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdt_TableCell:last-child {
    border-right: none;
  }
}
.stats_heading {
  color: #009b3a;
  width: 100%;
  height: 10%;
  background-color: rgba(24, 26, 44, 1);
  border-radius: 35px;
  h3 {
    margin: 0;
    padding: 0;
  }
}
.Editable_stats {
  width: 48%;
  height: 10%;
  background: linear-gradient(
    0deg,
    rgba(57, 60, 82, 0.02) -65.31%,
    rgba(255, 255, 255, 0) 100%,
    rgba(57, 60, 82, 0) 100%
  );
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  p {
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
    padding: 0;
  }
  .progress_cont {
    width: 30%;
  }
  .number_stat {
    color: #ffffff;
  }
  .number_input_cont {
    width: 35%;
  }
}
.Stats {
  width: 100%;
  // height: a%;
}
#parctice_modal-body {
  height: 75vh !important;
}
.date_box {
  width: 20%;
  height: 25vh;
  border-radius: 16px;
  border: 1px solid #ffffff;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  .design_box {
    width: 100%;
    height: 10%;
    transition: all ease-in-out 0.1s;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 108.03%
    );
    opacity: 0.1;
  }
  .date_inner {
    width: 100%;
    height: 60%;
    position: relative;
    p {
      margin: 0;
      padding: 0;
      font-size: 1rem;
    }
    h1 {
      padding: 0;
      margin: 0;
      font-size: 2.5rem;
    }
    .card {
      width: 30%;
      aspect-ratio: 1;
      background-color: rgba(37, 39, 59, 1);
      position: absolute;
      right: 5%;
      bottom: 5%;
      color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .card:nth-child(1) {
      bottom: 35%;
    }
    .card:nth-child(2) {
      bottom: 25%;
    }
    .card:nth-child(3) {
      bottom: 15%;
    }
  }
}
.date_box:hover {
  border: 1px solid #028a37;
  .design_box {
    background: linear-gradient(90deg, #009b3a 0%, rgba(0, 155, 58, 0) 108.03%);
    opacity: 1;
  }
}

.delete_box {
  width: 20%;
  height: 25vh;
  border-radius: 16px;
  border: 1px solid #ffffff;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  .design_box {
    width: 100%;
    height: 10%;
    transition: all ease-in-out 0.1s;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 108.03%
    );
    opacity: 0.1;
  }
  .date_inner {
    width: 100%;
    height: 60%;
    position: relative;
    p {
      margin: 0;
      padding: 0;
      font-size: 1rem;
    }
    h1 {
      padding: 0;
      margin: 0;
      font-size: 2.5rem;
    }
    .card {
      width: 30%;
      aspect-ratio: 1;
      background-color: rgba(37, 39, 59, 1);
      position: absolute;
      right: 5%;
      bottom: 5%;
      color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .card:nth-child(1) {
      bottom: 35%;
    }
    .card:nth-child(2) {
      bottom: 25%;
    }
    .card:nth-child(3) {
      bottom: 15%;
    }
  }
}
.delete_box:hover {
  border: 1px solid #9b0000;
  .design_box {
    background: linear-gradient(90deg, #9b0000 0%, rgba(0, 155, 58, 0) 108.03%);
    opacity: 1;
  }
}
#choose_player_modal-body {
  height: 77vh !important;
}
.choose_player_pos_cont {
  width: 90%;
}
.position {
  width: 22%;
  aspect-ratio: 1;
  background: linear-gradient(
    180deg,
    rgba(57, 60, 82, 0.2) 0%,
    rgba(57, 60, 82, 0.102) 100%
  );
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
}
.position:hover {
  border: 1px solid #028a37;
}
.position_active {
  border: 1px solid #028a37;
}
.player_practice {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  position: relative;
  width: 100%;
  height: 35vh;
  background: linear-gradient(
    180deg,
    rgba(57, 60, 82, 0.2) 0%,
    rgba(57, 60, 82, 0.102) 100%
  );
  backdrop-filter: blur(10px);

  border-radius: 16px;
  img {
    height: 100%;
  }
  .design_box {
    position: absolute;
    width: 6px;
    height: 70%;
    border-radius: 0px 10px 10px 0px;
    background-color: rgba(0, 155, 58, 1);
    left: 0;
    top: 15%;
  }
  .player_info {
    height: 100%;
    width: 50%;
    .fullwidth {
      width: 100%;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .complimentary {
      color: rgba(255, 255, 255, 0.5);
    }
    .contrast {
      color: #009b3a;
      text-transform: uppercase;
    }
    .top_info {
      font-size: 1.2rem;
    }
  }

  .jesrsey_number {
    font-family: "Roboto", sans-serif;
    // width: 20%;
    height: 100%;
    margin-left: 10%;
    font-size: 8rem;
    color: transparent;
    -webkit-text-stroke: 2px rgba(255, 255, 255, 0.7);
  }
}
.player_practice:hover {
  border: 1px solid #028a37;
  .design_box {
    background-color: #009b3a;
  }
}
.player_practice_delete:hover {
  border: 1px solid #9b0000;
  .design_box {
    background-color: #9b0000;
    opacity: 1;
  }
}
