.dashboard_cont {
  height: 100vh;
  width: 100vw;

  background-color: #101222;
  display: flex;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
  .dashboard_content {
    width: 85%;
    height: 100%;
    min-height: 100vh;
    margin-left: 15%;
    .main_top {
      width: 100%;
      height: 10%;
      color: rgba(255, 255, 255, 0.9);

      .welcome_text_cont {
        background-color: rgba(57, 60, 82, 0.1);
        border-radius: 30px;
      }
      .mantine-Input-input {
        background-color: transparent;
        border-color: rgba(57, 60, 82, 0.6);
      }
      .input_cont {
        width: 35%;
      }
      .main_top_right {
        width: 7%;
        .notification_cont {
          color: #ffffff;
        }
      }
    }
  }

  .sidebar {
    width: 15%;
    height: 100%;
    position: fixed;
    background-color: rgba(57, 60, 82, 0.2);
    .sidebar_logo {
      position: absolute;
      left: 20%;
      color: #ffffff;
      font-style: italic;
      img {
        height: 4vh;
        @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
          (min-resolution: 192dpi) {
          height: 3vh;
        }
        @media (min-width: 1024px) and (max-width: 1300px) {
          height: 3vh;
        }
      }
    }
    .sidebar_cont {
      height: 100%;
      width: 100%;
      padding-top: 50%;
      padding-bottom: 10%;
      padding-left: 15%;
      color: #ffffff;
      .sidebar_cont_top {
        height: 50%;
        @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
          (min-resolution: 192dpi) {
          height: 35%;
        }
      }
      .icon_cont {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        p {
          margin: 0;
          padding: 0;
          margin-left: 5%;
        }
      }
      .active_nav {
        border-right: 5px solid green;
        border-radius: 2%;
        color: green;
      }
    }
  }
  .dasboard_main {
    width: 85%;
    height: 100%;
    // min-height: ;
    height: 100vh;
    margin-left: 15%;
  }
}
