.Team_box {
  width: 20%;
  height: 25vh;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  img {
    width: 20%;
  }

  .design_box {
    width: 100%;
    height: 20%;

    background: linear-gradient(
      91.2deg,
      rgba(255, 255, 255, 0.21) 3.97%,
      rgba(255, 255, 255, 0) 99.29%
    );
  }
  background: linear-gradient(107.46deg, #101222 30.13%, #17192b 103.72%);
  cursor: pointer;
}
.Team_box:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: scale(1.05);
  transition: 0.05s;

  .design_box {
    background: linear-gradient(90deg, #009b3a 0%, rgba(0, 155, 58, 0) 108.03%);
    opacity: 1;
  }
}
.delete_Team_box:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: scale(1.05);
  transition: 0.05s;
  border: 1px solid #9b0000;
  .design_box {
    background: linear-gradient(90deg, #9b0000 0%, rgba(0, 155, 58, 0) 108.03%);
    opacity: 1;
  }
}
#note {
  font-size: 0.9rem;
  color: #ababab;
}
