.scout_main {
  width: 85%;
  height: 100vh;
  margin-left: 15%;
  .main_top {
    width: 100%;
    height: 10%;
    .mantine-Input-input {
      background-color: transparent;
      border-color: rgba(57, 60, 82, 0.6);
    }
    .input_cont {
      width: 35%;
    }
    .main_top_right {
      width: 7%;
      .notification_cont {
        color: #ffffff;
      }
    }
    .user_image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
    }
    .larger_top_right {
      width: 15%;
      .notification_cont {
        color: #ffffff;
      }
    }
  }
  #my_team {
    color: #ffffff;
  }
  .Coach_Player {
    color: #ffffff;
    width: 30vw;
    height: 25vh;
    border-radius: 16px;
    background-color: rgba(57, 60, 82, 0.2);
    position: relative;
    overflow: hidden;
    .close_button {
      position: absolute;
      right: 1%;
    }
    img {
      object-fit: contain;
      max-height: 22vh;
    }
    p {
      padding: 0;
      margin: 0;
    }
    .metric {
      color: #ababab;
      font-size: 0.7rem;
    }
    .value {
      font-size: 0.7rem;
    }
    .button_cont {
      width: 100%;
    }
    @media (min-width: 1024px) and (max-width: 1300px) {
      width: 37%;
    }
  }
}
.no_sidebar_cont {
  margin-left: 0 !important;
  width: 100%;
}
.team_select_cont {
  width: 100%;
  height: 100%;
  .team_select {
    width: 80%;
    height: 65%;
    border-radius: 10px;
    border-color: rgba(57, 60, 82, 0.6);
    background-color: rgba(57, 60, 82, 0.2);
    .team {
      height: 70%;
      width: 30%;
      background-color: rgba(57, 60, 82, 0.2);
      border-radius: 16px;
      color: #ffffff;
      transition: all 0.1s ease-in-out;
    }
    .team:hover {
      border: 1px solid #009b3a;
      transform: scale(1.05);
    }
  }
}
.back_icon {
  color: #ffffff;

  cursor: pointer;
}
.back_icon:hover {
  color: green;
}
.welcome_coach {
  height: 80vh;
  color: #ffffff;
  h4 {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }
}
.dashboard_cont {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
}
