.video_Editor_cont {
  width: 100%;
  height: 90%;

  .video_player_cont {
    width: 100%;
    margin-top: 2%;
  }
  .video_player {
    width: 65%;

    height: 90%;
    position: relative;
    .video_controls {
      padding-top: 1%;
      padding-bottom: 1%;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 17%;
      background-color: rgba(255, 255, 255, 0.02);
      .slider_cont {
        width: 99%;
      }
      .video_edit_controls {
        width: 100%;
        color: #ffffff;
        p {
          margin: 0;
        }
      }
      transition: all 0.1s ease-in-out;
    }
    .video_controls:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    .video_status_cirlce {
      position: absolute;
      width: 7%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.2);
      animation: scaleAndFade 1s forwards;
      .video_status_icon {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .other_videos {
    width: 35%; // adjust as needed
    height: 80vh; // adjust as needed
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  //   .other_video_cont {
  //     width: 70%;
  //   }
  .upload_video_cont {
    width: 100%;
    height: 90%;
    min-height: 80vh;
    .mantine-Dropzone-root {
      width: 100%;
      height: 80vh !important;

      background-color: rgba(255, 255, 255, 0.02);

      border: 2px dashed rgba(255, 255, 255, 0.5);

      .mantine-Dropzone-inner {
        width: 100%;
        height: 100%;
      }
    }
    [data-reject] {
      border-color: red;
    }
    [data-accept] {
      border-color: green;
    }
    .mantine-Dropzone-inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .uploaded_videos_cont {
    background-color: rgba(255, 255, 255, 0.02);
    // min-height: 80vh;
    .image_cont {
      width: 18%;
      max-height: 25vh;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
      .video_icon {
        position: absolute;
        bottom: 5%;
        right: 5%;
        font-size: 1.5rem;
        z-index: 3;
      }
      .video_icon:hover {
        cursor: pointer;
        color: green;
      }
      .date_badge {
        position: absolute;
        bottom: 15%;
        left: 5%;
      }
      .video_title {
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 5%;
        font-weight: bold;
        left: 5%;
        color: rgb(90, 90, 90);
      }
    }
  }
}
.team_name_input {
  width: 16vw;
}
.radio_image {
  height: 1vw;
}
.radio_text {
  font-size: 1.1rem;
}
.date_ai_assist {
  .mantine-Radio-radio {
    background-color: #232535;
    border: none;
  }
}
@keyframes scaleAndFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
