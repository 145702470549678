.Settings_cont {
  width: 85%;
  height: 100%;

  color: rgba(255, 255, 255, 1);
  p {
    color: rgba(255, 255, 255, 0.5);
  }
  .password_cont {
    width: 60%;
    .pasword_wrapper {
      width: 70%;
    }
    p {
      margin: 0;
      padding: 0;
      font-weight: bold;
    }
  }
  .button_cont {
    width: 70%;
  }
}
