@import url("https://fonts.googleapis.com/css2?family=Anek+Gurmukhi:wght@200;300;500;600;700&display=swap");
$mobile: 480px;
body {
  margin: 0 !important;
  padding: 0 !important;

  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
}
.training_feature_home_cont {
  width: 100vw;
  height: 340vh;
  @media (max-width: $mobile) {
    height: 780vh;
    overflow-x: hidden;
  }
  padding: 0;
  margin: 0;
  font-family: "Anek Gurmukhi", sans-serif;
  background-color: #0a0d1e;

  .training_feature_landing {
    width: 100vw;
    height: 170vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-top: 2%;
    // padding-left: 5%;
    // padding-right: 5%;
    @media (max-width: $mobile) {
      height: 120vh;
    }
  }
  .training_feature_landing:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    opacity: 0.1;
    background-image: url("../../public/images/home_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .landing_top {
    margin-left: 5%;
    margin-right: 5%;
    @media (max-width: $mobile) {
      margin-top: 10%;
    }

    .navbar {
      display: flex;
      justify-content: center;
    }
  }

  //.......................
  .training-features-images {
    display: flex;
    justify-content: center;
  }
  .training-features-images-inner {
    display: flex;
    flex-direction: column;
  }
  .img-1 {
    width: 650px;
    height: auto;

    margin-right: 30px;
  }
  .img-2 {
    width: 640px;
    height: auto;
    margin-bottom: 30px;
  }
  .img-3 {
    width: 600px;
  }

  //.......................

  // .grid-item img {
  //   width: 650px;
  //   height: auto;
  // }
  #top_logo {
    @media (max-width: $mobile) {
      width: 15%;
    }
  }
  #dashboard_btn {
    @media (max-width: $mobile) {
      display: none;
      visibility: hidden;
    }
  }
  #elipse_1 {
    width: 100vw;
    height: 30vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(10vw);

    position: absolute;
    top: -10vw;
    border-radius: 50%;
  }
  .landing_main {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "Anek Gurmukhi", sans-serif;
    .spaced_text {
      color: rgba(255, 255, 255, 0.2);
      font-size: 1.1rem;
      letter-spacing: 10px;
      @media (max-width: $mobile) {
        font-size: 1rem;
        letter-spacing: 7px;
      }
    }

    .button_cont {
      width: 20vw;
      @media (max-width: $mobile) {
        width: 60vw;
      }
    }
  }
  .laptop_cont {
    opacity: 1;
    height: 90vh;
    position: relative;
    img {
      opacity: 1;
    }
    #laptop_image {
      position: absolute;
      z-index: 3;
      filter: drop-shadow(10px 9px 59px #a4a4a44d);
      @media (max-width: $mobile) {
        max-width: 90vw;
      }
    }
    @media (max-width: $mobile) {
      height: 40vh;
    }
  }
}

.parental_engagement {
  width: 100vw;
  height: 100vh;
  text-align: center;
  .button_cont {
    width: 20vw;
    @media (max-width: $mobile) {
      width: 70vw;
    }
  }
  @media (max-width: $mobile) {
    height: 45vh;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.cta_form_features {
  width: 100vw;
  height: 120vh;
  padding-top: 2%;
  text-align: center;
  position: relative;
  overflow: hidden;
  h1 {
    color: #ffffff;
  }
  .form_outter {
    width: 40vw;
    height: 80vh;
    @media (max-width: $mobile) {
      width: 90vw;
    }
    /* From https://css.glass */
    background: rgba(57, 60, 82, 0.11);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(57, 60, 82, 0.07);
    z-index: 2;
    .choose_recepient {
      width: 80%;
      color: rgba(255, 255, 255, 0.5);
      .selected {
        color: #ffffff;
        text-decoration: underline;
      }
      h4 {
        cursor: pointer;
      }
    }
    .input_cont {
      width: 90%;
    }
    .button_cont {
      width: 40%;
      @media (max-width: $mobile) {
        width: 80%;
      }
    }
    .text_area_cont {
      width: 90%;
    }
    @media (min-width: 1023px) and (max-width: 1300px) {
      height: 85vh;
    }
  }
  #elipse_5 {
    width: 100vw;
    height: 60vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(4vw);

    position: absolute;
    bottom: -55vh;
    border-radius: 50%;
  }
}
.footer_features {
  width: 100vw;
  height: 30vh;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 2%;
  color: rgba(255, 255, 255, 0.7);
  .tag_cont {
    width: 100%;
    margin-top: 2%;
  }
  .logo_cont {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: start;
    img {
      height: 5vh;
    }
  }
  .tag {
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
  }
  .sitemap {
    margin-left: 30%;
  }
  .copyright {
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
  }
}
.training_feature_headline {
  color: #ffffff;
  // text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  span {
    color: transparent;
    background-image: linear-gradient(45deg, #00d2ff 0%, #3a7d2a 100%);
    background-clip: text;
  }
  @media (max-width: $mobile) {
    font-size: 1.3rem;
  }
}
.main_text {
  color: rgba(255, 255, 255, 0.4);
  // text-align: center;
  font-weight: 300;
  font-size: 1.3rem;

  span {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
  }
  @media (max-width: $mobile) {
    font-size: 0.9rem;
  }
}

//new pages styles
