.videoDownloadButtons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailError {
  color: red;
  display: flex;
  justify-content: center;
}
