@import url("https://fonts.googleapis.com/css2?family=Anek+Gurmukhi:wght@200;300;500;600;700&display=swap");
body {
  margin: 0 !important;
  padding: 0 !important;

  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
}
.home_cont {
  width: 100vw;
  height: 100vh;
  @media (max-width: 480px) {
    height: 100vh;
    overflow-x: hidden;
  }
  padding: 0;
  margin: 0;
  font-family: "Anek Gurmukhi", sans-serif;
  background-color: #0a0d1e;

  .landing {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-top: 2%;

    @media (max-width: 480px) {
      height: 120vh;
    }
  }
  .landing:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    opacity: 0.1;
    background-image: url("../../public/images/home_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .landing_top {
    margin-left: 5%;
    margin-right: 5%;
    @media (max-width: 480px) {
      margin-top: 10%;
    }
  }
  #top_logo {
    @media (max-width: 480px) {
      width: 15%;
    }
  }
  #dashboard_btn {
    @media (max-width: 480px) {
      display: none;
      visibility: hidden;
    }
  }
  #elipse_1 {
    width: 100vw;
    height: 30vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(10vw);

    position: absolute;
    top: -10vw;
    border-radius: 50%;
  }
  .landing_main {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "Anek Gurmukhi", sans-serif;
    .spaced_text {
      color: rgba(255, 255, 255, 0.2);
      font-size: 1.1rem;
      letter-spacing: 10px;
      @media (max-width: 480px) {
        font-size: 1rem;
        letter-spacing: 7px;
      }
    }

    .button_cont {
      width: 20vw;
      @media (max-width: 480px) {
        width: 60vw;
      }
    }
  }
  .headline {
    text-align: center;
    margin-top: 100px; /* Align the headline at the vertical center */
  }
  .laptop_cont {
    opacity: 1;
    height: 90vh;
    position: relative;
    img {
      opacity: 1;
    }
    #laptop_image {
      position: absolute;
      z-index: 3;
      filter: drop-shadow(10px 9px 59px #a4a4a44d);
      @media (max-width: 480px) {
        max-width: 90vw;
      }
    }
    @media (max-width: 480px) {
      height: 40vh;
    }
  }

  .slider_text {
    color: rgba(255, 255, 255, 0.173);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: 8px;
    span {
      font-size: 5rem;
      line-height: 1.5rem;
    }
    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
}
