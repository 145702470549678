.Onboarding {
  &-main {
    height: 100vh;
    width: 100vw;
    padding: 2%;
    padding-top: 2%;
    overflow: hidden;
    background-color: rgba(16, 18, 34, 1);
    color: #ffffff;
    .onboarding {
      &-top {
        width: 100%;
        position: relative;
        #back_button {
          position: absolute;
          left: 0;
        }
      }
      &-bottom {
        width: 100%;
        margin-top: 3%;
      }
    }
    #question_answers {
      margin-top: 5%;
      height: 60vh;
      .row {
        height: 100% !important;
      }
    }
    #answer_cont {
      padding-top: 10%;
      .answer {
        width: 100%;
        border-radius: 30px;
        border: 1px solid #ffffff;
        background-color: rgba(57, 60, 82, 0.2);
        box-shadow: rgba(106, 98, 98, 0.896) 1.95px 1.95px 2.6px;
        transition: all 0.05s ease-in-out;
        &:hover {
          cursor: pointer;
          border-color: #3e68ff;
          color: #3e68ff;
          box-shadow: rgba(129, 115, 115, 0.832) 0px 3px 8px;
        }
      }
    }
  }
}
.mantine-Stepper-separator {
  background-color: #3e68ff;
}
