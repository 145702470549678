.coach_stats_cont {
  width: 100%;
  height: 90%;
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
  color: #ffffff;
  .selection {
    cursor: pointer;
  }
  .Selected {
    color: #009b3a;
    border-bottom: 2px solid #009b3a;
  }

  .select_Stats {
    background-color: rgba(57, 60, 82, 0.2);
    width: 40vw;
    height: 65vh;
    padding: 4%;
    border-radius: 16px;
    .current_stat {
      background-color: rgba(57, 60, 82, 0.2);
      cursor: pointer;
      border-radius: 16px;
      .circle {
        width: 92px;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid #393c52;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        color: #009b3a;
      }
    }
  }
}
.custom_table {
  .rdt_Table {
    background-color: transparent;
  }
  .rdt_TableHeadRow {
    background-color: #191b2b;
    color: #009b3a;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 1%;
  }
  .rdt_TableCol {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdt_TableRow {
    background-color: #191b2b;
    color: #ffffff;
    border-radius: 10px;
    margin-bottom: 1%;
  }
  .rdt_TableCell {
    border-right: 1px solid #ffffff25;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdt_TableCell:last-child {
    border-right: none;
  }
}
.center_page {
  display: flex;
  align-items: center;
  justify-content: center;
}
