.Search_cont {
  width: 100%;
  height: 90%;
  color: #ffffff;
  .outter_search_box {
    background-color: rgba(57, 60, 82, 0.2);
    width: 50vw;
    height: 70vh;
    border-radius: 16px;
    .inner_search_box {
      background-color: rgba(57, 60, 82, 0.2);
      height: 60%;
      width: 35%;
      margin: 1%;
      border-radius: 16px;
    }
  }
}
.Player {
  color: #ffffff;
  width: 32%;
  height: 25vh;
  border-radius: 16px;
  background-color: rgba(57, 60, 82, 0.2);
  position: relative;
  overflow: hidden;
  img {
    object-fit: contain;
    max-height: 22vh;
  }
  p {
    padding: 0;
    margin: 0;
  }
  .metric {
    color: #ababab;
    font-size: 0.7rem;
  }
  .value {
    font-size: 0.7rem;
  }
  .button_cont {
    width: 100%;
  }
  @media (min-width: 1024px) and (max-width: 1300px) {
    width: 37%;
  }
}
.mantine-Modal-content {
  background-color: #101222 !important;
  color: #ffffff !important;
  border-radius: 16px !important;
  border: 1px solid #009b3a !important;
  // height: 50vh !important;
  width: 50vh !important;
}
.mantine-Modal-header {
  background-color: #101222 !important;
  .mantine-CloseButton-root {
    position: absolute !important;
    right: 1% !important;
    top: 5%;
    // color: rgba(195, 1, 1, 0.568) !important;
  }
}
// .close_button {
//   position: absolute !important;
//   left: 0%;
// }

.mantine-TextInput-label {
  color: #bababa !important;
}
.mantine-Input-input::placeholder {
  color: #cdcdcd !important;
}
#search_players_modal-body {
  height: 100% !important;
}
