.chooseuser_cont {
  width: 100vw;
  height: 100vh;
  background-color: rgba(16, 18, 34, 1);
  position: relative;
  color: #ffffff;
  justify-content: end;
  @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
    justify-content: center;
    padding-top: 10%;
  }
  .chooseuser_logo {
    position: absolute;
    top: 1%;
  }
  .signup {
    color: #009b3a;
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      font-size: 3.5rem;
    }
  }
  .charecter {
    opacity: 0.05;
    position: absolute;
    bottom: 0;
  }
  #left_charecter {
    left: 3%;
  }
  #center_charecter {
    left: 35%;
  }
  #right_charecter {
    left: 65%;
  }
  .chooseusers {
    .user {
      width: 20vw;
      height: 60vh;
      z-index: 3;
      /* From https://css.glass */
      /* From https://css.glass */
      background: rgba(57, 60, 82, 0.1);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(13.8px);
      -webkit-backdrop-filter: blur(13.8px);
      transition: all 0.1s ease-in-out;
      @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
        height: 50vh;
        width: 22vw;
      }
    }
    .user:hover {
      transform: scale(1.05);
      border: 1px solid #009b3a;
    }
    .Active {
      border: 1px solid #009b3a;
      img {
        filter: brightness(0) saturate(100%) hue-rotate(90deg);
      }
    }
  }
  .login {
    color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi) {
      font-size: 1.5rem;
    }
    cursor: default;
    span {
      color: #009b3a;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
