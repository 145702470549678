.section1-background {
  background-image: url("../../public/images/section1-bg-image.png"),
    url("../../public/images/section1-bg-image-2.png");
  background-position: top center, bottom center;
  background-repeat: no-repeat, no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: auto, cover;
}

.section2-background {
  background-image: url("../../public/images/section2-bg-image.png"),
    url("../../public/images/section2-bg-image2.png");
  background-position: top right, bottom center;
  background-repeat: no-repeat, no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: auto, cover;
}

.section3-background {
  background-image: radial-gradient(
      ellipse at right top,
      rgba(13, 40, 73, 0.7) 0%,
      transparent 10%
    ),
    url("../../public/images/section2-bg-image.png");
  background-position: top right, top right; /* Aligns both the gradient and the image */
  background-repeat: no-repeat;
  width: 100%;
  height: auto; /* Adjust based on content or specific design needs */
  min-height: 200px; /* Ensures that the bottom image has space if the content is not enough */
  background-size: cover, auto; /* Cover for the gradient, auto for the image */
}

/* Style for the main blog section */
.blog-section {
  padding-top: 40px;
  padding-bottom: 80px;
}

/* Style for the blog title with a background gradient */
.blog-title {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
}

/* Ellipse gradient behind the blog title */
.blog-title::before {
  content: "";
  position: absolute;
  top: -90%; /* Adjust to position correctly */
  left: 10%;
  right: 10%;
  bottom: 50%; /* Adjust the size of the gradient */
  background: radial-gradient(ellipse at center, #0d2849 0%, transparent 70%);
  z-index: -1; /* Ensures the gradient is behind the text */
}

.blog-bottom {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.blog-bottom::before {
  content: "";
  position: absolute;
  top: -90%; /* Adjust to position correctly */
  left: 10%;
  right: 10%;
  bottom: 50%; /* Adjust the size of the gradient */
  background: radial-gradient(ellipse at right, #0d2849 0%, transparent 70%);
  z-index: -1; /* Ensures the gradient is behind the text */
}

/*--------------------hide sc roll bar-----------------*/
.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
