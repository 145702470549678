.player_training {
  width: 100%;
  height: 100%;
  color: #ffffff;

  .user_training {
    width: 100%;
    height: 25vh;
    background-color: rgba(57, 60, 82, 0.2);
    border-radius: 16px;
    overflow: hidden;
    .player_date {
      height: 80%;
      width: 20%;
      border-right: 3px solid #393c52;
      position: relative;
      p {
        margin: 0;
        padding: 0;
      }
      .small_text {
        font-size: 1.4rem;
      }
      .large_text {
        font-size: 3.5rem;
        font-weight: 500;
      }
      .eclipse {
        position: absolute;
        border-radius: 50%;
        background: rgba(213, 225, 218, 0.2);
        filter: blur(3vw);
        width: 100%;
        height: 30%;
        bottom: 0;
        left: -20%;
      }
    }
    .attendance {
      width: 20%;
      height: 80%;
      border-right: 3px solid #393c52;
      p {
        margin: 0;
        padding: 0;
      }
      .top_attendance {
        border-bottom: 3px solid #393c52;
      }
      .complimnetary {
        color: #98989a;
        font-size: 0.8rem;
      }
    }
    #last_metrics {
      border-right: none;
    }
    .metrics {
      width: 20%;
      height: 80%;
      border-right: 3px solid #393c52;
      .metric {
        width: 100%;
      }
      p {
        margin: 0;
        padding: 0;
      }
      .complimentary {
        color: #98989a;
        font-size: 0.8rem;
      }
      .highlight {
        color: #028a37;
      }
      .metric_value {
        width: 100%;
      }
    }
  }
  .Empty_cont {
    margin-top: 2%;
    width: 100%;
    height: 85vh;
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.4);
  }
}
