.palyer_request {
  color: #ffffff;

  .contrast_text {
    color: rgba(255, 255, 255, 0.7);
  }
  .categories {
    width: 100%;
    height: 35vh;

    .category {
      position: relative;
      overflow: hidden;
      height: 100%;
      aspect-ratio: 1/1;
      border-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(57, 60, 82, 0.2) 0%,
        rgba(57, 60, 82, 0.102) 100%
      );
      backdrop-filter: blur(14.2484px);
      .glow {
        width: 40%;
        height: 60%;
        background: rgba(0, 155, 58, 0.2);

        filter: blur(2vw);

        position: absolute;
        top: -30%;
        left: 30%;
        border-radius: 50%;
      }
    }
    .category:hover {
      border: 1px solid #009b3a;
    }
  }
  .categories:hover {
    cursor: pointer;
  }
  .req_header {
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    background-color: rgba(24, 26, 44, 0.5);
    border-radius: 10px;
  }
  .requirement {
    width: 100%;
    .req_desc {
      width: 80%;
      p {
        color: rgba(255, 255, 255, 0.7);
      }
      h5 {
        color: rgba(255, 255, 255, 0.9);
      }
    }
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .requirement:hover {
    cursor: pointer;
  }
}

.sent_requests {
  width: 100%;
  background-color: rgba(24, 26, 43, 0.5);
  height: 20vh;
  border-radius: 16px;
  .label {
    outline: 1px solid#009b3a;
    border-radius: 10px;
    img {
      max-height: 40%;
    }
    p {
      margin: 0;
      padding: 0;
      color: #009b3a;
    }
  }
  .request_status {
    background: linear-gradient(
      90deg,
      rgba(0, 155, 58, 0.1) 0%,
      rgba(47, 237, 118, 0.02) 87.56%
    );
    outline: 1px solid #009b3a;
    border-radius: 16px;
    p {
      margin: 0;
      padding: 0;
      color: #009b3a;
    }
  }
}
#Player_request_modal-body {
  height: 80vh;
  .label {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.02) 87.56%
    );
    outline: 1px solid rgba(255, 255, 255, 0.66);
    border-radius: 10px;
    img {
      max-height: 40%;
    }
    p {
      margin: 0;
      padding: 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .request_status {
    outline: 1px solid #009b3a;
    border-radius: 16px;
    background: linear-gradient(
      90deg,
      rgba(0, 155, 58, 0.1) 0%,
      rgba(47, 237, 118, 0.02) 87.56%
    );
    p {
      margin: 0;
      padding: 0;
      color: #009b3a;
    }
  }
  .desc_text {
    color: rgba(255, 255, 255, 0.7);
  }
  .user_name {
    color: rgba(255, 255, 255, 0.7);
  }
  .comment {
    color: rgba(255, 255, 255, 0.5);
  }
}
