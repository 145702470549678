.dashboard_content {
  width: 85%;
  height: 100%;
  margin-left: 15%;
  .main_top {
    width: 100%;
    height: 10%;
    .mantine-Input-input {
      background-color: transparent;
      border-color: rgba(57, 60, 82, 0.6);
    }
    .input_cont {
      width: 35%;
    }
    .main_top_right {
      width: 7%;
      .notification_cont {
        color: #ffffff;
      }
      .user_image {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;
      }
    }
  }
  .Registered_players_cont {
    width: 100%;
    height: 90%;
    color: #ffffff;

    .Player {
      width: 32vw;
      height: 25vh;
      border-radius: 16px;
      background-color: rgba(57, 60, 82, 0.2);
      overflow: hidden;
      img {
        object-fit: contain;
        max-height: 25vh;
      }
      p {
        padding: 0;
        margin: 0;
      }
      .metric {
        color: #ababab;
        font-size: 0.7rem;
      }
      .value {
        font-size: 0.7rem;
      }
      .button_cont {
        width: 100%;
      }
      @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
        height: 20vh;
      }
      @media (min-width: 1024px) and (max-width: 1300px) {
        width: 37vw;
      }
    }
  }
}
.no_sidebar_cont {
  margin-left: 0 !important;
  width: 100%;
}
.Team_box {
  width: 20%;
  height: 25vh;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  img {
    width: 30%;
  }

  .design_box {
    width: 100%;
    height: 20%;

    background: linear-gradient(
      91.2deg,
      rgba(255, 255, 255, 0.21) 3.97%,
      rgba(255, 255, 255, 0) 99.29%
    );
  }
  background: linear-gradient(107.46deg, #101222 30.13%, #17192b 103.72%);
  @media only screen and (max-width: 1281px) and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
    height: 20vh;
    width: 17%;
  }
}
.back_icon {
  color: #ffffff;

  cursor: pointer;
}
.back_icon:hover {
  color: green;
}
.delete_coach:hover {
  border: 1px solid #9b0000;
}
.delete_player:hover {
  border: 1px solid #9b0000;
}
#remove_player_modal-body {
  .mantine-Modal-body {
    height: 90% !important;
  }
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
#CoachDetailsModal-body {
  height: 75vh;
  .CoachDetails_cont {
    width: 100%;
    height: 100%;

    .image_cont {
      width: 12vw;
      aspect-ratio: 1/1;
      border-radius: 16px;
      background-color: #070918;
      overflow: hidden;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: block;
      }
    }
    .Category {
      color: rgba(255, 255, 255, 0.6);
      font-weight: lighter;
      font-size: 1.1rem;
      margin: 0;
      padding: 0;
    }
    .Value {
      margin: 0;
      padding: 0;
      color: rgba(255, 255, 255, 0.9);

      font-size: 1.3rem;
    }
  }
}

.empty-coaches-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4vw;
  padding-left: 15vw;
}

.empty-players-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4vw;
  padding-left: 30vw;
}
