.playerRequirements_cont {
  width: 100%;
  height: 90%;
  color: #ffffff;

  .Player {
    width: 40vw;
    height: 30vh;
    margin-bottom: 2%;
    border-radius: 16px;
    background: linear-gradient(101.67deg, #181a2c 12.01%, #141628 91.75%);
    overflow: hidden;
    position: relative;
    .fullwidth {
      width: 100%;
    }
    .contrast {
      color: rgba(255, 255, 255, 0.5);
    }
    img {
      object-fit: contain;
      max-height: 25vh;
    }
    p {
      padding: 0;
      margin: 0;
    }
    .metric {
      color: #ababab;
      font-size: 0.7rem;
    }
    .value {
      font-size: 0.7rem;
    }
    .design_box {
      position: absolute;
      height: 70%;
      width: 5px;
      border-radius: 0px 20px 20px 0px;
      left: 0;
      top: 15%;
      background-color: rgba(68, 71, 89, 0.32);
    }
    .selected_player_glow {
      width: 10%;
      height: 60%;
      background: rgba(0, 155, 58, 0.2);

      filter: blur(2vw);

      position: absolute;
      top: 15%;
      left: -2%;
      border-radius: 50%;
    }
    .jersey {
      // width: 20%;
      height: 100%;
      margin-left: 10%;

      .jersey_number {
        font-size: 7rem;
        color: transparent;
        -webkit-text-stroke: 2px rgba(255, 255, 255, 0.7);
        font-family: "Roboto", sans-serif;
      }
    }
  }
  .Player:hover {
    .design_box {
      background: #009b3a;
    }
    outline: 1px solid #009b3a;
  }
  .category_top {
    width: 100%;
    height: 10%;
    background-color: rgba(24, 26, 44, 0.5);
    border-radius: 16px;
    .heading {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.3rem;
    }
  }
  .requirement_cont {
    width: 100%;
    .row {
      width: 100% !important;
    }
    .requirement {
      width: 100%;
      height: 20vh;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
    .edit_req:hover {
      cursor: pointer;
      filter: hue-rotate(120 - 0deg) // Adjust hue to green
        saturate(100%) // Increase saturation if needed
        brightness(50%); // Adjust brightness to match the desired lightness
    }
    .label {
      width: 80%;
      height: 40%;
      border-radius: 20px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.02) 87.56%
      );
      p {
        margin: 0;
        padding: 0;
      }
    }
    .status {
      width: 100%;
      height: 40%;
      border-radius: 20px;
      background: linear-gradient(
        90deg,
        rgba(0, 155, 58, 0.1) 0%,
        rgba(47, 237, 118, 0.02) 87.56%
      );
      border: 1px solid #009b3a;
      p {
        margin: 0;
        padding: 0;
        color: #009b3a;
      }
      .circle {
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background: linear-gradient(180deg, #009b3a 0%, #2fed76 100%);
      }
    }
  }
  .bordered_text {
    background-color: rgb(26, 29, 53);
    // width: 20%;
    border-radius: 20px;
  }
  .bordered_text_desc {
    background-color: rgb(26, 29, 53);
    width: 100%;
    border-radius: 20px;
  }
  .label {
    width: 20vh;
    height: 5vh;
    border-radius: 20px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.02) 87.56%
    );
    p {
      margin: 0;
      padding: 0;
    }
  }
  .status {
    width: 30vh;
    height: 5vh;
    border-radius: 20px;
    background: linear-gradient(
      90deg,
      rgba(0, 155, 58, 0.1) 0%,
      rgba(47, 237, 118, 0.02) 87.56%
    );
    border: 1px solid #009b3a;
    p {
      margin: 0;
      padding: 0;
      color: #009b3a;
    }
    .circle {
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background: linear-gradient(180deg, #009b3a 0%, #2fed76 100%);
    }
  }
  .label_name {
    width: 100%;
  }
  .comments {
    width: 100%;
    height: 40vh;

    border-radius: 16px;

    .comment {
      .profile_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #1e2136;
      }
      .user_comment {
        font-size: 0.8rem;
        p {
          margin: 0;
          padding: 0;
          color: rgba(255, 255, 255, 0.7);
        }
        .date {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#choose_requirement_modal-body {
  height: 50vh !important;
  .requirement_modal_cont {
    width: 100%;
    height: 100%;

    .requirement_categories {
      width: 90%;
      height: 70%;

      .requirement {
        position: relative;
        overflow: hidden;
        width: 32%;
        aspect-ratio: 1/1;
        border-radius: 16px;
        background: linear-gradient(
          180deg,
          rgba(57, 60, 82, 0.2) 0%,
          rgba(57, 60, 82, 0.102) 100%
        );
        backdrop-filter: blur(14.2484px);
        .glow {
          width: 40%;
          height: 60%;
          background: rgba(0, 155, 58, 0.2);

          filter: blur(2vw);

          position: absolute;
          top: -30%;
          left: 30%;
          border-radius: 50%;
        }
      }
      .requirement:hover {
        border: 1px solid #009b3a;
      }
    }
  }
}
#requirement_modal-body {
  height: 75vh !important;
  .player_requirement_cont {
    width: 100%;
    height: 100%;
    color: #ffffff;
  }
  .fullwidth {
    width: 100%;
  }
  h5 {
    margin: 0;
    padding: 0;
  }
  .label {
    width: 20vh;
    height: 5vh;
    border-radius: 20px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.02) 87.56%
    );
    p {
      margin: 0;
      padding: 0;
    }
  }
  .status {
    width: 30vh;
    height: 5vh;
    border-radius: 20px;
    background: linear-gradient(
      90deg,
      rgba(0, 155, 58, 0.1) 0%,
      rgba(47, 237, 118, 0.02) 87.56%
    );
    border: 1px solid #009b3a;
    p {
      margin: 0;
      padding: 0;
      color: #009b3a;
    }
    .circle {
      width: 10px;
      height: 10px;
      border: none;
      border-radius: 50%;
      background: linear-gradient(180deg, #009b3a 0%, #2fed76 100%);
    }
  }
  .desc {
    color: rgba(255, 255, 255, 0.7);
  }
}
