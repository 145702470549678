.Requirement_request_cont {
  width: 100%;
  height: 90%;
  color: #ffffff;

  .requests_top {
    width: 100%;
    height: 10%;
    background-color: rgba(24, 26, 44, 0.5);
    border-radius: 16px;
    .heading {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.3rem;
    }
  }
  .request_cont {
    width: 100%;

    .request {
      .player_name {
        font-size: 1.3rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
      }
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
  }
}
#requirement_request_modal-body {
  height: 75vh !important;

  .requirement_request_modal_cont {
    width: 100%;
    height: 100%;
    color: #ffffff;
  }
  .fullwidth {
    width: 100%;
  }
  h5 {
    margin: 0;
    padding: 0;
  }
  .label {
    width: 20vh;
    height: 5vh;
    border-radius: 20px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.02) 87.56%
    );
    p {
      margin: 0;
      padding: 0;
    }
  }

  .desc {
    color: rgba(255, 255, 255, 0.7);
  }
  .status_cont {
    width: 100%;
    .status_radio {
      width: 100%;
      .mantine-Radio-radio {
        background-color: #232535;
        border: none;
      }
    }
  }
  .buttons_cont {
    width: 100%;
    .button_cont {
      width: 35%;
    }
  }
}
