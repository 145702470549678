@import url("https://fonts.googleapis.com/css2?family=Anek+Gurmukhi:wght@200;300;500;600;700&display=swap");
$mobile: 480px;
body {
  margin: 0 !important;
  padding: 0 !important;

  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari and Opera */
  }
}
.home_cont {
  width: 100vw;
  height: auto;
  @media (max-width: $mobile) {
    height: 780vh;
    overflow-x: hidden;
  }
  padding: 0;
  margin: 0;
  font-family: "Anek Gurmukhi", sans-serif;
  background-color: #0a0d1e;
  select {
    background-color: #1e2132 !important;
    color: white !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  option {
    background-color: #1e2132 !important;
    color: white !important;
  }

  .landing {
    width: 100vw;
    height: 200vh;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding-top: 2%;
    // padding-left: 5%;
    // padding-right: 5%;
    @media (max-width: $mobile) {
      height: 120vh;
    }
  }
  .landing:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    opacity: 0.1;
    background-image: url("../../public/images/home_bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .landing_top {
    margin-left: 5%;
    margin-right: 5%;
    @media (max-width: $mobile) {
      margin-top: 10%;
    }

    .navbar {
      display: flex;
      justify-content: center;
    }
  }
  #top_logo {
    @media (max-width: $mobile) {
      width: 15%;
    }
  }
  #dashboard_btn {
    @media (max-width: $mobile) {
      display: none;
      visibility: hidden;
    }
  }
  #elipse_1 {
    width: 100vw;
    height: 30vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(10vw);

    position: absolute;
    top: -10vw;
    border-radius: 50%;
  }
  .landing_main {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "Anek Gurmukhi", sans-serif;
    .spaced_text {
      color: rgba(255, 255, 255, 0.2);
      font-size: 1.1rem;
      letter-spacing: 10px;
      @media (max-width: $mobile) {
        font-size: 1rem;
        letter-spacing: 7px;
      }
    }

    .button_cont {
      width: 20vw;
      @media (max-width: $mobile) {
        width: 60vw;
      }
    }
  }
  .laptop_cont {
    opacity: 1;
    height: 90vh;
    position: relative;
    img {
      opacity: 1;
    }
    #laptop_image {
      position: absolute;
      z-index: 3;
      filter: drop-shadow(10px 9px 59px #a4a4a44d);
      @media (max-width: $mobile) {
        max-width: 90vw;
      }
    }
    @media (max-width: $mobile) {
      height: 40vh;
    }
  }
  .slider_text {
    color: rgba(255, 255, 255, 0.173);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: 8px;
    span {
      font-size: 5rem;
      line-height: 1.5rem;
    }
    @media (max-width: $mobile) {
      font-size: 0.8rem;
    }
  }
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiper-slide {
  border-bottom: 2px solid rgba(255, 255, 255, 0.173);
  border-top: 2px solid rgba(255, 255, 255, 0.173);
  display: flex;
  align-items: center;
}
.parental_engagement {
  width: 100vw;
  height: 100vh;
  text-align: center;
  .button_cont {
    width: 20vw;
    @media (max-width: $mobile) {
      width: 70vw;
    }
  }
  @media (max-width: $mobile) {
    height: 45vh;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.practice_based_prediction {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  padding-top: 10%;
  @media (max-width: $mobile) {
    height: 50vh;
  }
  h1 {
    text-align: center;
    color: #ffffff;
    font-size: 4rem;
    @media (max-width: $mobile) {
      font-size: 1.8rem;
    }
  }
  .self_image {
    width: 100%;
    height: 60%;
    position: relative;
    @media (max-width: $mobile) {
      height: 40%;
    }
    .custompill {
      width: 15vw;
      aspect-ratio: 263/74;
      border-radius: 35px;
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: #0a0c18;
        font-size: 1.7rem;

        font-weight: 700;
        margin: 0;
        padding: 0;
        @media (max-width: $mobile) {
          font-size: 0.5rem;
        }
      }
      @media (max-width: $mobile) {
      }
    }
    #Accuracy {
      left: 12vw;
      background-color: #008632;
    }
    #footwork {
      left: -3vw;
      background-color: rgba(0, 134, 49, 0.6);
    }
    #Mobility {
      left: 23vw;
      top: 15vh;
      background-color: rgb(87, 255, 150);
      @media (max-width: $mobile) {
        top: 5vh;
      }
    }
    #Arm_Strength {
      left: 8vw;
      top: 15vh;
      background-color: rgba(87, 255, 150, 0.4);
      @media (max-width: $mobile) {
        top: 5vh;
      }
    }
    #Empty_1_left {
      left: -7vw;
      top: 15vh;
      background-color: rgba(87, 255, 150, 0.1);
      @media (max-width: $mobile) {
        top: 5vh;
      }
    }
    #Timming {
      top: 30vh;
      left: 10vw;
      background-color: rgb(23, 193, 87);
      @media (max-width: $mobile) {
        top: 10vh;
      }
    }
    #Progression {
      top: 30vh;
      left: -5vw;
      background-color: rgba(23, 193, 87, 0.3);
      @media (max-width: $mobile) {
        top: 10vh;
      }
    }
    #Precision {
      right: 17vw;
      background-color: rgba(127, 136, 197, 1);
    }
    #Agile {
      right: 2vw;
      background-color: rgba(127, 136, 197, 0.4);
    }
    #Empty_2_right {
      right: -13vw;
      background-color: rgba(127, 136, 197, 0.1);
    }
    #Dynamic {
      right: 23vw;
      top: 15vh;
      background-color: rgba(67, 75, 125, 1);
      @media (max-width: $mobile) {
        top: 5vh;
      }
    }
    #Potent {
      right: 8vw;
      top: 15vh;
      background-color: rgba(67, 75, 125, 0.5);
      @media (max-width: $mobile) {
        top: 5vh;
      }
    }
    #Empty_2_rightt {
      right: -7vw;
      top: 15vh;
      background-color: rgba(67, 75, 125, 0.2);
      @media (max-width: $mobile) {
        top: 5vh;
      }
    }
    #Synchronized {
      top: 30vh;
      right: 13vw;
      background-color: rgba(38, 47, 103, 1);
      @media (max-width: $mobile) {
        top: 10vh;
      }
    }
    #Evolving {
      top: 30vh;
      right: -2vw;
      background-color: rgba(38, 47, 103, 0.5);
      @media (max-width: $mobile) {
        top: 10vh;
      }
    }
  }
  #elipse_2 {
    width: 90vw;
    height: 30vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(10vw);

    position: absolute;
    // top: -10vw;
    border-radius: 50%;
  }
  .line_box_left {
    position: absolute;
    border-top: 2px solid green;
    border-right: 2px solid green;
    .line_box_inner {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  #line_box_left_1 {
    width: 18vw;
    height: 43vh;
    top: 5vh;
    left: 25vw;
    @media (max-width: $mobile) {
      top: 1vh;
      height: 21vh;
      left: 27vw;
      width: 20vw;
    }
  }
  #arrow_left_1 {
    position: absolute;
    right: -0.7vw;
    top: 20%;
    @media (max-width: $mobile) {
      right: -2.5vw;
      width: 15px;
    }
  }
  #line_box_left_2 {
    width: 3vw;
    height: 28vh;
    top: 20vh;
    left: 38vw;
    @media (max-width: $mobile) {
      top: 6vh;
      height: 16vh;
    }
  }
  #arrow_left_2 {
    position: absolute;
    right: -0.7vw;
    top: 20%;
    @media (max-width: $mobile) {
      width: 15px;
      right: -2.2vw;
    }
  }
  #line_box_left_3 {
    width: 14vw;
    height: 13vh;
    top: 35vh;
    left: 25vw;
    @media (max-width: $mobile) {
      top: 11vh;
      width: 10vw;
      height: 11vh;
    }
  }
  #arrow_left_3 {
    position: absolute;
    right: -0.7vw;
    top: 20%;
    @media (max-width: $mobile) {
      width: 15px;
      right: -2.2vw;
    }
  }
  .line_box_right {
    position: absolute;
    border-top: 2px solid white;
    border-left: 2px solid white;
    .line_box_inner {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
  #line_box_right_1 {
    width: 10vw;
    height: 43vh;
    top: 5vh;
    right: 32vw;
    @media (max-width: $mobile) {
      top: 1vh;
      height: 21vh;
      right: 27vw;
      width: 20vw;
    }
  }
  #arrow_right_1 {
    position: absolute;
    left: -0.7vw;
    top: 20%;
  }
  #line_box_right_1 {
    width: 10vw;
    height: 43vh;
    top: 5vh;
    right: 32vw;
  }
  #line_box_right_2 {
    width: 2vw;
    height: 28vh;
    top: 20vh;
    right: 38vw;
  }
  #arrow_right_2 {
    position: absolute;
    left: -0.7vw;
    top: 20%;
  }
  #line_box_right_3 {
    width: 10vw;
    height: 13vh;
    top: 35vh;
    right: 28vw;
  }
  #arrow_right_3 {
    position: absolute;
    left: -0.7vw;
    top: 20%;
  }
}
.management_tools {
  width: 100vw;
  height: 100vh;
  @media (max-width: $mobile) {
    padding-left: 7%;
    padding-right: 7%;
    height: 50vh;
  }
  text-align: center;
  .button_cont {
    width: 20vw;
    @media (max-width: $mobile) {
      width: 70vw;
    }
  }
}
.features {
  width: 100vw;
  height: 100vh;

  .features_cont {
    width: 100%;
    height: 60%;
    @media (max-width: $mobile) {
      height: 100%;
    }

    .feature {
      text-align: center;
      height: 75%;
      width: 20%;
      /* From https://css.glass */
      background: rgba(255, 255, 255, 0.026);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8.8px);
      -webkit-backdrop-filter: blur(8.8px);
      @media (max-width: $mobile) {
        height: 30%;
        width: 80%;
        background: none;
        box-shadow: none;
        backdrop-filter: none;
      }

      h2 {
        color: #ffffff;
      }
      p {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .feature:hover {
      border: 1px solid rgba(31, 39, 88, 1);
      box-shadow: rgba(217, 217, 217, 0.05) 0px 30px 60px -12px inset,
        rgba(217, 217, 217, 0.05) 0px 18px 36px -18px inset,
        rgba(217, 217, 217, 0.05) 0px 3px 8px;
    }
  }
}
.insights {
  width: 100vw;
  height: 140vh;
  @media (max-width: $mobile) {
    padding-left: 5%;
    padding-right: 5%;
    height: 110vh;
  }

  .insights_text {
    margin-top: 15%;
    @media (max-width: $mobile) {
      margin-top: 5%;
      text-align: center;
    }
  }
  .insights_image {
    width: 40%;
    height: 90%;
    // border-top: 5px solid #ffffff;
    // border-bottom: 5px solid #ffffff;
    // border-left: 5px solid #ffffff;
    border-radius: 35px 0 0 35px;
    position: relative;
    @media (max-width: $mobile) {
      width: 100%;
    }

    #name_card {
      position: absolute;
      top: -10%;
      left: 15%;
      @media (min-width: 1023px) and (max-width: 1300px) {
        left: 0%;
      }
      @media (max-width: $mobile) {
        width: 30vh;
        top: 0;
      }
    }

    #ring_prog {
      position: absolute;
      top: 30%;
      left: -17%;
      z-index: 5;
      @media (max-width: $mobile) {
        width: 30vh;

        left: 0;
      }
    }
    #bar_prog {
      position: absolute;
      top: 60%;
      left: 10%;
      @media (min-width: 1023px) and (max-width: 1300px) {
        left: -5%;
      }
      @media (max-width: $mobile) {
        width: 30vh;

        left: 25%;
      }
    }
    #elipse_3 {
      width: 100vw;
      height: 30vw;
      background: #ffffff;
      mix-blend-mode: overlay;
      filter: blur(10vw);

      position: absolute;
      top: 10vw;
      border-radius: 50%;
    }
  }
}
.instant_messaging {
  width: 100vw;
  height: 220vh;
  overflow: hidden;
  position: relative;
  text-align: center;
  @media (max-width: $mobile) {
    height: 120vh;
  }

  .messaging {
    width: 100vw;
    height: 100vh;
    padding-top: 10%;
    text-align: center;
    position: relative;
    @media (max-width: $mobile) {
      height: 60vh;
    }
    h3 {
      color: #2fed76;
    }
    .button_cont {
      width: 20vw;
      z-index: 2;
      @media (max-width: $mobile) {
        width: 70vw;
      }
    }
    #elipse_4 {
      width: 50vw;
      height: 30vw;
      background: #ffffff;
      mix-blend-mode: overlay;
      filter: blur(20vw);

      position: absolute;
      top: 20vw;
      border-radius: 50%;
    }
  }
  .diagram {
    width: 100vw;
    height: 120vh;
    @media (max-width: $mobile) {
      height: 50vh;
    }
    padding-top: 10%;
    .user_cont {
      width: 14vw;
      height: 15vw;
      /* From https://css.glass */
      background: rgba(57, 60, 82, 0.06);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(12.2px);
      -webkit-backdrop-filter: blur(12.2px);
      position: relative;
      overflow: hidden;
      p {
        color: #ffffff;
        font-size: 2rem;
        margin-bottom: 0;
        @media (max-width: $mobile) {
          font-size: 0.8rem;
        }
      }
      .user_elipse {
        width: 10vw;
        height: 2vh;
        background: #ffffff;
        mix-blend-mode: overlay;
        filter: blur(3vw);

        position: absolute;
        top: 0;
        border-radius: 50%;
      }
      @media (max-width: $mobile) {
        width: 30vw;
        height: 30vw;
        margin: 1%;
      }
    }
    .users {
      width: 60vw;
      height: 20vh;
      margin-left: 20vw;
      position: relative;
      @media (max-width: $mobile) {
        width: 90vw;
        margin-left: 5vw;
      }
      img {
        height: 50%;
      }

      #connection_top_left {
        width: 20vw;
        height: 10vh;
        left: 7vw;
        top: -15vh;
        position: absolute;
        border-top: 2px solid #009b3a;
        border-left: 2px solid #009b3a;
        border-right: 2px solid #009b3a;
        @media (max-width: $mobile) {
          top: -5vh;
          left: 17vw;
        }
      }
      #connection_top_right {
        width: 20vw;
        height: 10vh;
        right: 7vw;
        top: -15vh;
        position: absolute;
        border-top: 2px solid #009b3a;
        border-left: 2px solid #009b3a;
        border-right: 2px solid #009b3a;
        @media (max-width: $mobile) {
          top: -5vh;
          right: 17vw;
        }
      }
      #connection_center {
        width: 10vw;
        height: 10vh;
        left: 23vw;
        top: 32vh;
        position: absolute;
        transform: rotate(90deg);
        border-top: 2px solid #ffffff;
      }
      #connection_bottom_left {
        width: 16vw;
        height: 43vh;
        left: 7vw;
        top: -28vh;
        position: absolute;
        transform: rotate(180deg);
        border-top: 2px solid #ffffff;

        border-right: 2px solid #ffffff;
        @media (max-width: $mobile) {
          height: 21vh;
          top: -7.5vh;
          left: 13vw;
        }
      }
      #connection_bottom_right {
        width: 16vw;
        height: 43vh;
        right: 7vw;
        top: -28vh;
        position: absolute;
        transform: rotate(180deg);
        border-top: 2px solid #ffffff;

        border-left: 2px solid #ffffff;
        @media (max-width: $mobile) {
          height: 21vh;
          top: -7.5vh;
          right: 13vw;
        }
      }
    }
    #user_top {
      margin-bottom: 35vh;
      @media (max-width: $mobile) {
        margin-bottom: 5vh;
      }
    }
  }
}

.instant_messaging:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 30%;
  width: 100%;
  height: 70%;
  opacity: 0.1;
  background-image: url("../../public/images/home_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.cta_form {
  width: 100vw;
  height: 120vh;
  padding-top: 2%;
  text-align: center;
  position: relative;
  overflow: hidden;
  h1 {
    color: #ffffff;
  }
  .form_outter {
    width: 40vw;
    height: 80vh;
    @media (max-width: $mobile) {
      width: 90vw;
    }
    /* From https://css.glass */
    background: rgba(57, 60, 82, 0.11);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(57, 60, 82, 0.07);
    z-index: 2;
    .choose_recepient {
      width: 80%;
      color: rgba(255, 255, 255, 0.5);
      .selected {
        color: #ffffff;
        text-decoration: underline;
      }
      h4 {
        cursor: pointer;
      }
    }
    .input_cont {
      width: 90%;
    }
    .button_cont {
      width: 40%;
      @media (max-width: $mobile) {
        width: 80%;
      }
    }
    .text_area_cont {
      width: 90%;
    }
    @media (min-width: 1023px) and (max-width: 1300px) {
      height: 85vh;
    }
  }
  #elipse_5 {
    width: 100vw;
    height: 60vw;
    background: #ffffff;
    mix-blend-mode: overlay;
    filter: blur(4vw);

    position: absolute;
    bottom: -55vh;
    border-radius: 50%;
  }
}
.footer {
  width: 100vw;
  height: 50vh;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 2%;
  color: rgba(255, 255, 255, 0.7);
  .tag_cont {
    width: 100%;
    margin-top: 2%;
  }
  .logo_cont {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: start;
    img {
      height: 5vh;
    }
  }
  .tag {
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
  }
  .sitemap {
    margin-left: 30%;
  }
  .copyright {
    width: 100%;
    color: rgba(255, 255, 255, 0.7);
    p {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
    }
  }
}
.headline {
  color: #ffffff;
  // text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  span {
    color: transparent;
    background-image: linear-gradient(45deg, #009b3a, #2fed76);
    background-clip: text;
  }
  @media (max-width: $mobile) {
    font-size: 1.3rem;
  }
}
.main_text {
  color: rgba(255, 255, 255, 0.4);
  // text-align: center;
  font-weight: 300;
  font-size: 1.3rem;

  span {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
  }
  @media (max-width: $mobile) {
    font-size: 0.9rem;
  }
}

//new pages styles
