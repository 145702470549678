.scout_main {
  width: 85%;
  height: 100%;
  margin-left: 15%;
  .main_top {
    width: 100%;
    height: 10%;
    .mantine-Input-input {
      background-color: transparent;
      border-color: rgba(57, 60, 82, 0.6);
    }
    .input_cont {
      width: 35%;
    }
    .main_top_right {
      width: 7%;
      .notification_cont {
        color: #ffffff;
      }
    }
  }
  .empty_search {
    width: 40%;
    height: 50%;
    border-radius: 16px;
    background-color: rgba(57, 60, 82, 0.2);
    position: absolute;
    top: 25%;
    left: 40%;
    color: #7c7c7c;
    .circle {
      width: 10%;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 2px solid #ffffff;
    }
  }
  .scout_search {
    width: 40%;
    height: 40%;
    border-radius: 16px;
    background-color: rgba(57, 60, 82, 0.2);
    position: absolute;
    top: 30%;
    left: 40%;
    color: #7c7c7c;
    .metric_search {
      width: 100% !important;
      input {
        border-radius: 16px;
      }
    }
    .mantine-Input-input {
      background-color: rgba(57, 60, 82, 0.2);
      border: none;
      color: #ffffff;
    }
    .mantine-NumberInput-control {
      color: #ffffff !important;
      border: none;
    }
    .mantine-NumberInput-control:hover {
      background-color: transparent;
    }
    button {
      width: 60%;
      color: black !important;
    }
  }
}
