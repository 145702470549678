body {
  overflow-y: auto;
}
.video_Editor_cont {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #101222;
  padding-left: 2%;
  padding-right: 2%;
  overflow: hidden;
  color: #ffffff;
  .video_editing_top {
    width: 100%;
  }

  .video_player_cont {
    width: 100%;
    height: 100%;
    margin-top: 2%;
  }
  .video_player {
    width: 65%;

    height: 80%;
    position: relative;
    .video_controls {
      padding-top: 1%;
      padding-bottom: 1%;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 17%;
      background-color: rgba(255, 255, 255, 0.02);
      .slider_cont {
        width: 99%;
      }
      .video_edit_controls {
        width: 100%;
        color: #ffffff;
        p {
          margin: 0;
        }
      }
      transition: all 0.1s ease-in-out;
    }
    .video_controls:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    .video_status_cirlce {
      position: absolute;
      width: 7%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.2);
      animation: scaleAndFade 1s forwards;
      .video_status_icon {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .other_videos {
    width: 30%;
    margin-left: 5%; // adjust as needed
    max-height: 80vh; // adjust as needed
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    &::-webkit-scrollbar {
      display: none; /* For Chrome, Safari and Opera */
    }
  }
  .other_video_cont {
    width: 80%;
    height: 25vh;
    position: relative;
    background-color: rgba(255, 255, 255, 0.02);
    padding: 2%;
    border-radius: 18px;
    .video_icon {
      position: absolute;
      right: 5%;
      top: 5%;
      font-size: 2rem;
    }
    .video_data {
      width: 15%;
      height: 100%;
      .number_box {
        width: 70%;
        border-radius: 5px;
        font-size: 1.5rem;
        aspect-ratio: 1/1;
        background-color: #070911;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .video_position {
      color: green;
      font-size: 1.5rem;
      font-weight: bold;
    }

    img {
      width: 70%;
      max-height: 100%;
      margin-right: 10%;
    }
  }
  .upload_video_cont {
    width: 100%;
    height: 90%;
    .mantine-Dropzone-root {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.02);

      border: 2px dashed rgba(255, 255, 255, 0.5);

      .mantine-Dropzone-inner {
        width: 100%;
        height: 100%;
      }
    }
    [data-reject] {
      border-color: red;
    }
    [data-accept] {
      border-color: green;
    }
    .mantine-Dropzone-inner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .grid_cont {
    width: 100%;
    height: 40vh;
  }
}
@keyframes scaleAndFade {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
#preloadVideo {
  display: none;
  visibility: hidden;
}
.video_control_icon:hover {
  color: green;
}
.control:hover {
  color: green;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}
.disabled:hover {
  cursor: not-allowed;
}
.choosen_video {
  border: 1px solid green;
}
.delete_video:hover {
  border: 1px solid #9b0000;
}

.send-options {
  display: flex;
  justify-content: center;
}
