.player_practice_main {
  color: #ffffff;
  width: 100%;
  height: 90vh;
  .date_cont {
    .date_box {
      width: 20%;
      height: 25vh;
      border-radius: 16px;
      border: 1px solid #ffffff;
      transition: all ease-in-out 0.1s;
      cursor: pointer;
      .design_box {
        width: 100%;
        height: 10%;
        transition: all ease-in-out 0.1s;
        background: linear-gradient(
          90deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 108.03%
        );
        opacity: 0.1;
      }
      .date_inner {
        width: 100%;
        height: 60%;
        position: relative;
        p {
          margin: 0;
          padding: 0;
          font-size: 1rem;
        }
        h1 {
          padding: 0;
          margin: 0;
          font-size: 2.5rem;
        }
        .card {
          width: 30%;
          aspect-ratio: 1;
          background-color: rgba(37, 39, 59, 1);
          position: absolute;
          right: 5%;
          bottom: 5%;
          color: #ffffff;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .card:nth-child(1) {
          bottom: 35%;
        }
        .card:nth-child(2) {
          bottom: 25%;
        }
        .card:nth-child(3) {
          bottom: 15%;
        }
      }
    }
    .date_box:hover {
      border: 1px solid #028a37;
      .design_box {
        background: linear-gradient(
          90deg,
          #009b3a 0%,
          rgba(0, 155, 58, 0) 108.03%
        );
        opacity: 1;
      }
    }
  }

  .Editable_stats {
    width: 48%;
    height: 10%;
    background: linear-gradient(
      0deg,
      rgba(57, 60, 82, 0.02) -65.31%,
      rgba(255, 255, 255, 0) 100%,
      rgba(57, 60, 82, 0) 100%
    );
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    p {
      color: rgba(255, 255, 255, 0.5);
      margin: 0;
      padding: 0;
    }
    .progress_cont {
      width: 30%;
    }
    .number_stat {
      color: #ffffff;
    }
    .number_input_cont {
      width: 35%;
    }
  }
  .Stats {
    width: 100%;
    // height: a%;
  }
  .stats_heading {
    color: #009b3a;
    width: 100%;
    height: 15%;
    background-color: rgba(24, 26, 44, 1);
    border-radius: 35px;
    h3 {
      margin: 0;
      padding: 0;
    }
  }
  .Empty_cont {
    margin-top: 2%;
    width: 99%;
    height: 85vh;
    background-color: rgba(255, 255, 255, 0.02);
    color: rgba(255, 255, 255, 0.4);
  }
}
